import { useState, useEffect } from "react"
import {setmappingkey, uploadFile, selectFileuploadres, resetfileuploadaction} from "../../../Services/pipelines/pipeline.slice";
import {getValfromPipe} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import Output from "../Output/Output";
import { IconButton, LinearProgress, TextField, Tooltip, inputAdornmentClasses} from "@mui/material";
import CreateFolderModal from "../../filesystem/FolderCreateModal";
import {getFolders, selectFolders, createFile, selectFileCreatedAction, selectFileCreatedRes, resetfilecreated, getWriteFolders, selectWriteFolders} from "../../../Services/filesystem/filesystem.slice";
import FileSystem from "../../filesystem/FileSystem";
import "./Files.css";
import {getObjectVal} from "../../utils";
import { useParams } from "react-router-dom";

const FileField = ({
                        sc, 
                        schemaMapping,
                        openaddschema,
                        toggleSubSchema,
                        subschemaopen,
                        pipelinetype,
                        stageindex,
                        stagetype,
                        pipelinestage,
                        modifySchema,
                        templateid,
                        indexarr,
                        source,
                        sourceid
})=>{

        const {workspace} = useParams();

        const [filename, setFilename] = useState("");

        const [loading, setLoading] = useState(false);

        const handleChange = (e)=>{
            let file = e.target.files[0];
            setFilename(file.name);
            dispatch(resetfilecreated());
            setLoading(true);
            if(source=="apps"){
                dispatch(createFile({
                    "file": file,
                    "path": schemaMapping.mapping.path,
                    "workspace": workspace,
                    "source": source,
                    "sourceid": sourceid
                }))
            }else if(source=="apptemplates"){
                dispatch(createFile({
                    "file": file,
                    "path": schemaMapping.mapping.path,
                    "workspace": workspace,
                    "source": source,
                    "sourceid": templateid
                }))
            }else if(source=="appvariables"){
                dispatch(createFile({
                    "file": file,
                    "path": schemaMapping.mapping.path,
                    "workspace": workspace,
                    "source": source,
                    "sourceid": sourceid
                }))
            }else if(source=="appprops"){
                dispatch(createFile({
                    "file": file,
                    "path": schemaMapping.mapping.path,
                    "workspace": workspace,
                    "source": source,
                    "sourceid": sourceid
                }))
            }else if(source=="appinteractions"){
                dispatch(createFile({
                    "file": file,
                    "path": schemaMapping.mapping.path,
                    "workspace": workspace,
                    "source": source,
                    "sourceid": sourceid
                }))
            }
        }

        const [openFieldDec, setOpenFieldDec] = useState(false);

        const [openUrlFieldDec, setOpenUrlFieldDec] = useState(false);

        const [actionsToggle, setActionsToggle] = useState(false);
        
        const [schemaval, setschemaval] = useState("");
    
        const [schemavalget, setschemavalget] = useState("");
        
        const dispatch = useDispatch();
    
        const [inputval ,setInputval] = useState({});
    
        const [error, setError] = useState([]);
    
        // 0 -> Const value , 1-> value from pipeline 2-> value from url
        const [fileInputMethod, setFileInputMethod] = useState(0);

        const toggleSubSchemaInternal = ()=>{
            toggleSubSchema();
            setActionsToggle(!actionsToggle);
        }

        const [actionActive, setActionActive] = useState(false);

        const fileuploadres = useSelector(selectFileCreatedRes);
        
        const setFileSystemSchemamapping = (output)=>{
            let mappingCopy = {
                                "action": "const",
                                "val": output,
                                "inputtype":2
                              }
            let schemaMappingCopy = {...schemaMapping,
                                     mapping: mappingCopy
                                    };
            modifySchema(sc, schemaMappingCopy);
        }

        const setConstUrlMapping = (val)=>{
            let mappingCopy = {
                                "action": "urlconst",
                                "val": val,
                                "inputtype": 2
                              }
            let schemamappingCopy = {...schemaMapping,
                                     mapping: mappingCopy
                                    }
            modifySchema(sc, schemamappingCopy);
        }

        const setGetUrlMapping = (val)=>{
            let mappingCopy = {
                                "action": "urlget",
                                "val": val.schemaval,
                                "inputype": 2
                              }
            let schemaMappingCopy = {...schemaMapping,
                                     mapping: mappingCopy
                                    }
            modifySchema(sc, schemaMappingCopy);
        }

        const setSchemaMappingData = ()=>{
            return (val)=>{
                let mappingCopy = {};
                if(schemaMapping?.mapping?.action!="get"){
                    dispatch(setmappingkey({
                                            oldkey: "",
                                            key:val.schemaval,
                                            stageindex:stageindex, 
                                            stagetype: stagetype
                    }))
                }else{
                    dispatch(setmappingkey({
                        oldkey: schemaMapping.mapping.val,
                        key:val.schemaval,
                        stageindex:stageindex, 
                        stagetype: stagetype
                    }))
                }
                if(sc.required){
                    mappingCopy = {...mappingCopy,
                                   "action":"get",
                                    "val": val.schemaval,
                                    "inputtype": 1
                                  };                    
                }else{
                    mappingCopy = {...mappingCopy,
                                   "action": "get",
                                   "val": val.schemaval,
                                   "inputtype": 1
                                  }
                }
                let schemamappingCopy = {...schemaMapping,
                                         mapping: mappingCopy
                                        };
                modifySchema(sc, schemamappingCopy);
                // setschemavalget(val.schemaval);
                // setInputval(val.value);
            }
        }

        // sets schemamapping for the action const
        useEffect(()=>{
            if(fileuploadres?.name==filename){
                let output = {...fileuploadres};
                // setInputval(output);
                // setschemavalget("");
                let mappingCopy = {...schemaMapping.mapping,
                                    "action": "const",
                                    "val": output,
                                    "inputtype": 0,
                                    "file": output
                                  }
                let schemaMappingCopy = {...schemaMapping,
                                         mapping: mappingCopy
                                        }
                setLoading(false);
                modifySchema(sc, schemaMappingCopy);
            }
        },[fileuploadres]);
    
        // set schemavalget on every schemamapping change
        useEffect(()=>{
            let sm = schemaMapping;
            if(sm?.mapping?.val!==undefined){
                setschemaval(sm.mapping.val);
                if(sm.mapping.action=="const"){
                    setInputval(sm.mapping.val);
                    setschemavalget("");
                    if(sm.mapping.inputtype==2&&fileInputMethod!=2){
                        setFileInputMethod(2);
                    }

                }
                if(sm.mapping.action=="get"){      
                    setschemavalget(sm.mapping.val);
                    if(sm.mapping.inputtype==1&&fileInputMethod!=1){
                        setFileInputMethod(1)
                    }
                }
                if(sm.mapping.action=="urlget"){
                    setInputval(sm.mapping.val);
                    setschemavalget(sm.mapping.val);
                    if(fileInputMethod!=2){
                        setFileInputMethod(2)
                    }
                }
                if(sm.mapping.action=="urlconst"){
                    setInputval(sm.mapping.val);
                    setschemavalget("");
                    if(fileInputMethod!=2){
                        setFileInputMethod(2);
                    }
                }
            }
            setError("");
        },[openFieldDec,sc, schemaMapping])

        useEffect(()=>{
            let sm = schemaMapping;
            if(sm?.mapping?.val!=undefined){
                let schemavalc = "";
                let schemavalgetc = "";
                let inputvalc = "";
                if(sm.mapping.action=="const"){
                    if(fileInputMethod==0&&schemaval==""){
                        schemavalc = sm.mapping.val;
                    }
                    if(source=="apps"&&fileInputMethod==2&&schemaval==""){
                        schemavalc = sm.mapping.val;
                    }
                }
                if(sm.mapping.action=="get"){      
                    if(fileInputMethod==1&&schemaval==""){
                        schemavalc = sm.mapping.val;
                    }
                }
                if(sm.mapping.action=="urlget"){
                    if(fileInputMethod==2&&schemaval==""){
                        schemavalc = sm.mapping.val;   
                    }
                }
                if(sm.mapping.action=="urlconst"){
                    if(fileInputMethod==2&&schemaval==""){
                        schemavalc = sm.mapping.val;
                    }
                }
                if(fileInputMethod==0&&sm.mapping.action!="const"){
                    schemavalc = ""
                }else if(fileInputMethod==1&&sm.mapping.action!="get"){
                    schemavalc = ""
                }else if(
                            fileInputMethod==2
                            &&(
                              sm.mapping.action=="const"||
                              sm.mapping.action=="get"  
                            )
                        ){
                    schemavalc = ""      
                }
                setschemaval(schemavalc);
            }
        },[fileInputMethod])
    
        useEffect(()=>{
            if(openaddschema){
                setActionActive("newschema")
            }else{
                setActionActive("")
            }
        },[openaddschema])
    
        useEffect(()=>{
            if(subschemaopen){
                setActionActive("expand")
            }else{
                setActionActive("")
            }
        },[subschemaopen])

        // file path controls
        const [createFolder, setCreateFolder] = useState(false);

        useEffect(()=>{
            if(sc.createallowed==false){
                setFileInputMethod(1);
            }
            if(source=="apps"){
                dispatch(getWriteFolders({
                    workspace: workspace,
                    source: source,
                    sourceid: sourceid
                }));
            }else if(source=="apptemplates"){
                dispatch(getWriteFolders({
                    workspace: workspace,
                    source: source,
                    sourceid: templateid
                }))
            }else if(source=="appvariables"){
                dispatch(getWriteFolders({
                    workspace: workspace,
                    source: source,
                    sourceid: sourceid
                }))
            }else if(source=="appprops"){
                dispatch(getWriteFolders({
                    workspace: workspace,
                    source: source,
                    sourceid: sourceid
                }))
            }else if(source=="appinteractions"){
                dispatch(getWriteFolders({
                    workspace: workspace,
                    source: source,
                    sourceid: sourceid
                }))
            }
            else if(source=="texttemplates"){
                dispatch(getWriteFolders({
                    workspace: workspace,
                    source: source,
                    sourceid: sourceid
                }))
            }
        },[sc])

        const folders = useSelector(selectWriteFolders);

        useEffect(()=>{
            if(folders==undefined&&folders==null){
                return
            }
            if(folders.length==0){
                return;
            }
            if(schemaMapping?.mapping?.path==""||schemaMapping?.mapping?.path==undefined){
                let mappingCopy = {...schemaMapping.mapping};
                mappingCopy = {...mappingCopy,
                               path: "tmp"
                              }
                let schemamappingCopy = {...schemaMapping,
                                         mapping: mappingCopy
                                        }
                modifySchema({...sc},schemamappingCopy);
            }
        },[folders])

        const changeFilePath = (path)=>{
            let mappingCopy = {...schemaMapping.mapping};
            mappingCopy = {...mappingCopy,
                           path: path
                          }
            let schemamappingCopy = {...schemaMapping,
                                     mapping: mappingCopy
                                    }
            modifySchema({...sc}, schemamappingCopy);
        }

    // have type 
    return (
        <>
            <div className="insert-data-tile">
                <div className="data-label">
                    {sc.label}
                    {sc.required&&
                        <>
                            *
                        </>
                    }
                    <div className={(actionActive=="expand")?"data-action-toggles-button":"data-action-toggles-button"}>
                        {actionActive=="expand"?
                            <Tooltip title="expand schema">
                                <IconButton onClick={()=>{toggleSubSchemaInternal()}}>
                                    <span className="material-symbols-outlined">
                                        unfold_more_double
                                    </span>
                                </IconButton>
                            </Tooltip>:
                            <Tooltip title="expand schema">
                            <IconButton onClick={()=>{toggleSubSchemaInternal()}}>
                                <span className="material-symbols-outlined">
                                    unfold_less_double
                                </span>
                            </IconButton>
                        </Tooltip>
                        }
                    </div>
                </div>
                <div>
                    <div className="decalare-variable-data-type">
                        <span style={{padding: 10}}>
                            type: {sc.type}
                        </span>
                        <span style={{padding: 10,display: "flex", alignItems: "center"}}>
                            {/* sample input: <pre>{getSampleInput(sc)}</pre> */}
                        </span>
                        <span style={{display: "flex" ,alignItems: "center", maxWidth: 500}}>
                            input: <pre 
                                        className="files-input-display"
                                    >
                                        {schemavalget==""?JSON.stringify(inputval, null, " "):JSON.stringify(getObjectVal(sc.subschema,schemaMapping,{}), null, " ")}
                                    </pre>
                        </span>
                        
                    </div>
                </div>
                {subschemaopen?
                    <>
                        <div className="data-example">
                            {loading&&
                                <LinearProgress></LinearProgress>
                            }
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div className="input-type-switch">
                                    {sc.createallowed!=false&&
                                        <div onClick={()=>{setFileInputMethod(0)}} className={fileInputMethod==0?"input-type active":"input-type"}>
                                            Input
                                        </div>
                                    }
                                    <div onClick={()=>{setFileInputMethod(1)}} className={fileInputMethod==1?"input-type active":"input-type"}>
                                        From Pipeline
                                    </div>
                                    {source=="apps"?
                                        <div onClick={()=>{setFileInputMethod(2)}} className={fileInputMethod==2?"input-type active":"input-type"}>
                                            From Filesystem
                                        </div>:
                                        <div onClick={()=>{setFileInputMethod(2)}} className={fileInputMethod==2?"input-type active":"input-type"}>
                                            From Url
                                        </div>
                                    }
                                </div>
                                {/* component to create and select file when creating new file */}
                                {fileInputMethod==0&&
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <span style={{padding: 4}}>
                                            path:
                                        </span>
                                        <div style={{padding: 4}}>
                                            <select
                                                value={schemaMapping.mapping.path}
                                                onChange={(e)=>{changeFilePath(e.target.value)}}
                                            >
                                                {folders!=undefined&&folders!=null&&
                                                    <>
                                                        {folders.map((folder)=>{
                                                            return(
                                                                <option value={folder.path}>{folder.path}</option>
                                                            )
                                                        })}    
                                                    </>
                                                }
                                                
                                            </select>
                                        </div>
                                        <IconButton onClick={()=>{setCreateFolder(true)}}>
                                            <span class="material-symbols-outlined">
                                                create_new_folder
                                            </span>
                                        </IconButton> 
                                    </div>
                                }
                            </div>
                            {fileInputMethod==0&&sc.createallowed!=false&&folders!=undefined&&folders!=null&&folders.length>0&&
                                <>
                                    <input 
                                        type="file" 
                                        onChange={(e)=>{handleChange(e)}}
                                    ></input>
                                </>
                            }
                            {fileInputMethod==1&&
                                <>
                                    <TextField
                                            error={error!=""} 
                                            variant="outlined" 
                                            fullWidth 
                                            value={schemaval} 
                                            onClick={()=>{setOpenFieldDec(!openFieldDec)}}
                                            help={sc?.default==""?sc?.help:sc?.help+" defaults to "+sc?.default}
                                    ></TextField>
                                </>
                            }
                            {fileInputMethod==2&&
                                <>
                                    {source=="apps"?
                                        <>
                                            <FileSystem
                                                pipelinemode={true}
                                                schemaMapping={schemaMapping}
                                                setSchemaMappingData={setFileSystemSchemamapping}
                                            >
                                            </FileSystem>   
                                        </>:
                                        <>
                                            <TextField
                                                variant="outlined"
                                                value={schemaval}
                                                onClick={()=>{setOpenUrlFieldDec(!openUrlFieldDec)}}
                                                onChange={(e)=>{setConstUrlMapping(e.target.value)}}
                                                help={sc?.default==""?sc?.help:sc?.help+" defaults to "+sc?.default}
                                            ></TextField>
                                        </>
                                    }
                                </>
                            }
                            {openUrlFieldDec&&
                                <Output
                                    closeFieldSelector={setOpenUrlFieldDec}
                                    type={"string"}
                                    setschemaMapping={setGetUrlMapping}
                                    pipelinetype={pipelinetype}
                                    pipelinestage={pipelinestage}
                                    templateid={templateid}
                                    indexarr={indexarr}
                                    source={source}
                                >
                                </Output>

                            }
                            {openFieldDec&&
                                <Output 
                                    closeFieldSelector={setOpenFieldDec} 
                                    type={sc.type} 
                                    setschemaMapping={setSchemaMappingData()}
                                    pipelinetype={pipelinetype}
                                    pipelinestage={pipelinestage}
                                    templateid={templateid}
                                    indexarr = {indexarr}
                                    source={source}
                                ></Output>
                            }
                        </div>
                    </>:
                    <></>
                }
            </div>
            <CreateFolderModal
                open={createFolder}
                handleClose={()=>{setCreateFolder(false)}}
            >
            </CreateFolderModal>
        </>
    )

}


export default FileField;
import * as _ from "lodash";
import * as moment from "moment";


export const parseSchemaKey = (val)=>{
    let steps = val.split(".");
    let appstep = steps[0];
    let appstepsplit = appstep.split("[");
    appstepsplit = appstepsplit[1];
    appstepsplit = appstepsplit.split("]");
    let appindex = appstepsplit[0];
    steps[0] = appindex;
    return steps;
}

const getStageType = (val)=>{
    let steps = val.split(".");
    let appstep = steps[0];
    let appstepsplit = appstep.split("[");
    let stagetype = appstepsplit[0];
    appstepsplit = appstepsplit[1];
    appstepsplit = appstepsplit.split("]");
    let appindex = appstepsplit[0];
    var checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$")
    let tempvar = checkForHexRegExp.test(appindex);
    if(tempvar){
        return stagetype;
    }else{
        return "pipeline"
    }
}

export const searchPipeline = (pipeline,searchdata)=>{


    const searchPipelineInternal = (pipeline, searchdata, result)=>{
        for(let i=0; i< pipeline.length; i++){
            let stage = pipeline[i];
            let keys = Object.keys(searchdata);
            let matched = false;
            for(let i=0; i < keys.length; i++){
                let keymatched = stage[keys[i]]==searchdata[keys[i]];
                matched = matched&&keymatched;
            }
            if(matched){
                result.push({...stage});
            }
            if(stage.type=="conditional"){
                for(let j=0; j< stage.conditions.length; j++){
                    let condition = stage.conditions[j];
                    searchPipelineInternal(condition.pipeline, searchdata, result);
                }
            }
            if(stage.type=="loop"){
                searchPipelineInternal(stage.loop, searchdata, result);            
            }
        }
    }

    let result = [];
    searchPipelineInternal(pipeline, searchdata, result);
    return result;
    
}

export const generateSchema = (output, schema)=>{
    if(typeof(output)=="object"){
        if(output?._type=="file"){
            // unreachable code
            schema.push({
                "key": output._key,
                "label": output._key,
                "required": false,
                "type": "file",
                "value": {"url": output.url, "size": output.size, "name": output.name, "lastModified": output.lastModified},
                "subschema":[
                    {
                        "key": "size",
                        "label": "Size",
                        "required": false,
                        "type": "integer",
                        "value": output.size,
                        "subschema":[]
                    },
                    {
                        "key": "name",
                        "label": "Name",
                        "required": false,
                        "type": "string",
                        "value": output.name,
                        "subschema":[]
                    },
                    {
                        "key": "url",
                        "label": "URL",
                        "required": false,
                        "type": "string",
                        "value": output.url,
                        "subschema":[]
                    },
                    {
                        "key": "lastModified",
                        "label": "LastModified",
                        "required": false,
                        "type": "datetime",
                        "value": output.updatedAt,
                        "subschema":[]
                    }

                ] 
            })
            delete output._type;
            delete output._key;
        }else{
            _.mapKeys(output,(val,key)=>{
                let keyval = output[key];
                if(keyval==null||keyval==undefined){
                    schema.push({
                        "key": key,
                        "label": key,
                        "required": false,
                        "value": keyval,
                        "type": "any",
                        "subschema": []
                    })
                }else{
                    if(typeof(keyval)=="object"){
                        if(Array.prototype.isPrototypeOf(keyval)){
                            let subschema = [];
                            let firstval = keyval[0];
                            // check the viability of object , defaulting to string
                            if(firstval==undefined){
                                subschema = [{"type": "any", "subschema":[]}]                        
                            }else{
                                if(typeof(firstval)=="object"){
                                    let subsubschema = [];
                                    generateSchema(firstval,subsubschema)
                                    if(Array.prototype.isPrototypeOf(firstval)){
                                        subschema = [{"type": "array", "subschema":subsubschema}];
                                    }else{
                                        subschema= [{"type": "object","subschema": subsubschema}];
                                    }
                                }else{
                                    subschema = [{"type": typeof(firstval), "subschema":[]}]
                                }
                            }
                            schema.push({
                                "key": key,
                                "label": key,
                                "required": false,
                                "type": "array",
                                "value": keyval,
                                "subschema":subschema
                            })
        
                        }else{
                            if(val?._type=="file"){
                                schema.push({
                                    "key": key,
                                    "label": key,
                                    "required": false,
                                    "type": "file",
                                    "value": {"url": val.url, "size": val.size, "name": val.name, "lastModified": val.lastModified},
                                    "subschema":[
                                        {
                                            "key": "size",
                                            "label": "Size",
                                            "required": false,
                                            "type": "integer",
                                            "value": val.size,
                                            "subschema":[]
                                        },
                                        {
                                            "key": "name",
                                            "label": "Name",
                                            "required": false,
                                            "type": "string",
                                            "value": val.name,
                                            "subschema":[]
                                        },
                                        {
                                            "key": "url",
                                            "label": "URL",
                                            "required": false,
                                            "type": "string",
                                            "value": val.url,
                                            "subschema":[]
                                        },
                                        {
                                            "key": "lastModified",
                                            "label": "LastModified",
                                            "required": false,
                                            "type": "datetime",
                                            "value": val.updatedAt,
                                            "subschema":[]
                                        }
        
                                    ] 
                                })
                                delete val._type;
                            }else if(Date.prototype.isPrototypeOf(val)){
                                schema.push({
                                    "key": key,
                                    "label": key,
                                    "required": false,
                                    "value": keyval,
                                    "type": "datetime",
                                    "subschema": []
                                })   
                            }
                            else{
                                let subschema = [];
                                generateSchema(keyval, subschema)
                                schema.push({
                                    "key": key,
                                    "label": key,
                                    "required": false,
                                    "type": "object",
                                    "value": keyval,
                                    "subschema": subschema
                                })
                            }
                            
                        }
                    }else if(typeof(keyval)=="file"){
        
                    }
                    else{
                        schema.push({
                            "key": key,
                            "label": key,
                            "required": false,
                            "value": keyval,
                            "type": typeof(keyval),
                            "subschema": []
                        })
                    }
                } 
            })
        }
    }else{
        schema.push({"type": typeof(output)})
    };
}

export const getObjectFromIndexArr = (schema, indexarr)=>{
    let schemaCopy = [...schema];
    let val;
    if(indexarr[indexarr.length-1]==-1){
        val=undefined
    }else{
        for(let i=0; i< indexarr.length; i++){
            if(i==(indexarr.length-1)){
                if(schemaCopy[indexarr[i]].deleted){
                    val = undefined;
                }else{
                    val = schemaCopy[indexarr[i]].value
                }
            }else{
                if(schemaCopy[indexarr[i]].type=="array"){
                    if((i+1)==(indexarr.length-1)){
                        val = schemaCopy[indexarr[i]].value[indexarr[i+1]];
                        break;
                    }else{
                        let valt = schemaCopy[indexarr[i]].value[indexarr[i+1]];
                        let newsch = generateSchema(valt);
                        schemaCopy = newsch;
                        i = i+1
                    }
                }else{
                    if(schemaCopy[indexarr[i]].deleted){
                        val = undefined;
                        break;
                    }else{
                        schemaCopy = schemaCopy[indexarr[i]].subschema;
                    }
                }
                
            }
        }
    }
    return val;
}


const getSchemaValue = (schema)=>{
    let objectval = {};
    for(let i=0; i<schema.length; i++){
        if(schema[i].type=="object"){
            let nestestedval = getSchemaValue(schema[i].subschema);
            objectval[schema[i].key] = nestestedval;
        }else{
            objectval[schema[i].key] = schema[i].value
        }
    }
    return objectval;
}

const getObjectSchemaFromIndexArr = (schema, indexarr)=>{
    let schemaCopy = [...schema];
    let val;
    let type;
    let objecttype;
    let objsubschema;    
    if(indexarr[indexarr.length-1]==-1){
        val=undefined
    }else{
        for(let i=0; i< indexarr.length; i++){
            if(i==(indexarr.length-1)){
                if(schemaCopy[indexarr[i]].deleted){
                    val = [];
                }else{
                    if(schemaCopy[indexarr[i]].type=="object"){
                        objsubschema = schemaCopy[indexarr[i]].subschema;
                        type = "schema";
                        objecttype = "object"
                        val = getSchemaValue(schemaCopy[indexarr[i]].subschema)
                    }else{
                        val = schemaCopy[indexarr[i]].value;
                        type = "val";
                        objecttype = schemaCopy[indexarr[i]].type;
                        objsubschema = schemaCopy[indexarr[i]].subschema;
                    }
                    
                }
            }else{
                if(schemaCopy[indexarr[i]].type=="array"){
                    if((i+1)==(indexarr.length-1)){
                        val = schemaCopy[indexarr[i]].value[indexarr[i+1]];
                        type = "val";
                        objecttype="arrayitem";
                        break;
                    }else{
                        let valt = schemaCopy[indexarr[i]].value[indexarr[i+1]];
                        let newsch = generateSchema(valt);
                        schemaCopy = newsch;
                        i = i+1
                    }
                }else{
                    if(schemaCopy[indexarr[i]].deleted){
                        val = undefined;
                        break;
                    }else{
                        schemaCopy = schemaCopy[indexarr[i]].subschema;
                    }
                }   
            }
        }
    }
    return {
        val,
        type,
        objecttype,
        objsubschema
    };
}



export const getReferenceSchema = (stageIndex, executablePipeline)=>{
    let refStage = executablePipeline[stageIndex].refStage;
    let objectIndexArr = executablePipeline[stageIndex].valIndexArr;
    let objectKey = executablePipeline[stageIndex].key;
    let objectVal = {};
    let refschema = [];
    if(executablePipeline[refStage].type=="reference"){
        refschema = getReferenceSchema(refStage, executablePipeline);
    }else{
        refschema = executablePipeline[refStage].outputschema
    }
    let res = getObjectSchemaFromIndexArr(refschema, objectIndexArr);
    if(res.type=="schema"){
        let objectSchema = [{
            "key": objectKey,
            "label": objectKey,
            "value": res.val,
            "subschema": res.objsubschema,
            "type": "object",
            "required": false
        }];
        return objectSchema;
    }else if(res.type=="val"){
        if(res.objecttype=="arrayitem"){
            objectVal[objectKey] = res.val;
            let objectSchema = [];
            generateSchema(objectVal, objectSchema);
            return objectSchema;
        }else{
            let objectSchema = [{
                "key": objectKey,
                "label": objectKey,
                "value": res.val,
                "subschema": res.objsubschema,
                "type": res.objecttype,
                "required": false
            }];
            return objectSchema;
        }
    }
}
// export const getReferenceSchema = (stageIndex, executablePipeline)=>{
//     let refStage = executablePipeline[stageIndex].refStage;
//     let objectIndexArr = executablePipeline[stageIndex].valIndexArr;
//     let objectKey = executablePipeline[stageIndex].key;
//     let objectVal = {};
//     let refschema = [];
//     if(executablePipeline[refStage].type=="reference"){
//         refschema = getReferenceSchema(refStage, executablePipeline);
//     }else{
//         refschema = executablePipeline[refStage].outputschema
//     }
//     objectVal[objectKey] = getObjectFromIndexArr(refschema, objectIndexArr);
//     let objectSchema = [];
//     generateSchema(objectVal, objectSchema);
//     return objectSchema;
// }



const downloadFile = async(payload)=>{
    try{
        let res = await fetch(payload.url);
        let blob = await res.blob();
        let file = new File([blob], payload.name, { lastModified: new Date().getTime(), type: blob.type })
        return file;
    }catch(error){
        throw error;
    }
}


const getFileFromInput = async (value, index)=>{
    let fileschema = value[index].subschema;
    let urlindex = _.findIndex(fileschema, (sc)=>{return sc.key=="url"});
    if(fileschema[urlindex].value!=""){
        let nameindex = _.findIndex(fileschema, (sc)=>{return sc.key=="name"})
        let file = await downloadFile({
            url: fileschema[urlindex].value,
            name: fileschema[nameindex].value
        })
        return file;
    }else{
        let file = new File(["foo"], "foo.txt", {
            type: "text/plain",
          });
        return file;
    }
}



//check for transfroms on values after the index
// check the speed and if required change to output instead of outputschema
export const getValfromPipe = async (val, executablePipeline, type)=>{
    let parsedVal = parseSchemaKey(val);
    let value;
    let stagetype = getStageType(val);
    try{
        for(let i=0; i < parsedVal.length; i++){
            if(i==0){
                // handle transforms
                // for loops
                // add if check if the stage output is reference or stage is value.
                if(executablePipeline[parsedVal[i]].type=="reference"){
                    value = getReferenceSchema(parsedVal[i], executablePipeline);
                }else{
                    if(parsedVal[i+1]=="current_index"){
                        let val = executablePipeline[parsedVal[i]].output.array_to_loop[executablePipeline[parsedVal[i]].current_index];
                        let valueschema = [];
                        generateSchema(val, valueschema);
                        if(valueschema[0].type!="object"){
                            value = val;
                            i=i+1;
                        }else{
                            value = valueschema;
                            i=i+1;
                        }
                    }else{
                        value = executablePipeline[parsedVal[i]].outputschema;
                    }
                }   
            }else if(i==(parsedVal.length-1)){
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                }else{
                    if(type=="file"){
                        if(stagetype=="inputs"){
                            let file = await getFileFromInput(value, concernedkeyindex);
                            value = file;
                        }else{
                            value = value[concernedkeyindex].value.file;
                        }
                    }else if(value[concernedkeyindex].value==undefined&&value[concernedkeyindex].type=="object"){
                        value = getSchemaValue(value[concernedkeyindex].subschema);
                    }else{
                        value = value[concernedkeyindex].value;
                    }
                }
            }
            else{
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                    break;
                }else{
                    value = value[concernedkeyindex].subschema;
                }
            }
        }
    }catch(error){
        value = undefined;
    }
    return value;
}

const parsePrimitives = async (schema, keymapping, executablePipeline)=>{
    let val;
    if(schema.type=="string"||schema.type=="enum"){
        val = "";
        if(keymapping!=""){
            if(keymapping.action=="const"){
                val = keymapping.val;
            }else if(keymapping.action=="get"){
               val = await getValfromPipe(keymapping.val, executablePipeline);
            }
            // default to the schema default if value is null
        }else{
            val = undefined
        }
        if((val===""||val===undefined)&&schema.default!=undefined){
            val = schema.default;
        }
    }else if(schema.type=="text"){
        val = "";
        if(keymapping!=""){
            if(keymapping.action=="const"){
                val = keymapping.val;
            }else if(keymapping.action=="get"){
                val = await getValfromPipe(keymapping.val, executablePipeline);
            }
            // default to the schema default if value is null
        }else{
            val=undefined
        }
        if((val===""||val===undefined)&&schema.default!=undefined){
            val = schema.default;
        }
    }else if(schema.type=="file"){
        val = {};
        if(keymapping!=""){
            if(keymapping.action=="const"){
                val = JSON.parse(JSON.stringify(keymapping.val));
                val._type = "file";
                val._key = schema.key;
            }else if(keymapping.action=="get"){
                val = await getValfromPipe(keymapping.val, executablePipeline, "file");
            }
        }else{
            val = undefined;
        }
    }else if(schema.type=="integer"){
        if(keymapping!=""){
            if(keymapping.action=="const"){
                val = parseInt(keymapping.val);
            }else if(keymapping.action=="get"){
                val = await getValfromPipe(keymapping.val, executablePipeline);
                val = parseInt(val);
            }
        }else{
            val = undefined
        }
        if((val===""||val===undefined)&&schema.default!=undefined){
            val = schema.default;
        }
    }else if(schema.type=="number"){
        if(keymapping!=""){
            if(keymapping.action=="const"){
                val = parseFloat(keymapping.val);
            }else if(keymapping.action=="get"){
                val = await getValfromPipe(keymapping.val, executablePipeline);
                val = parseFloat(val)
            }
        }else{
            val = undefined
        }
        if((val===""||val===undefined)&&schema.default!=undefined){
            val = schema.default;
        }
    }else if(schema.type=="boolean"){
        if(keymapping!=""){
            if(keymapping.action=="const"){
                if(keymapping.val=="false"){
                    val=false
                }else if(keymapping.val=="true"){
                    val=true;
                }
            }else if(keymapping.action=="get"){
                val = await getValfromPipe(keymapping.val, executablePipeline);
                if(val=="false"){
                    val = false
                }else if(val=="true"){
                    val = true
                }
            }
        }else{
            val = undefined
        }
        if((val===""||val===undefined)&&schema.default!=undefined){
            val = schema.default;
        }
    }else if(schema.type=="datetime"){
        if(keymapping!=""){
            if(keymapping.action=="const"){
                val = moment(keymapping.val).toDate();
            }else if(keymapping.action=="get"){
                val = await getValfromPipe(keymapping.val, executablePipeline);
                val = moment(val).toDate()
            }
        }else{
            val = undefined
        }
        if((val===""||val===undefined)&&schema.default!=undefined){
            val = schema.default;
        }
    }else if(schema.type=="any"){
        if(keymapping!=""){
            if(keymapping.action=="const"){
                val = keymapping.val;
            }else if(keymapping.action=="get"){
                val = await getValfromPipe(keymapping.val, executablePipeline);
            }
        }else{
            val = undefined
        }
        if((val===""||val===undefined)&&schema.default!=undefined){
            val = schema.default;
        }
    }

    return val;
}


const getTypeObject = (type, val)=>{
    if(type=="string"||type=="enum"){
        return val.toString()
    }else if(type=="text"){
        return val.toString()
    }else if(type=="integer"){
        let res = parseInt(val);
        return res
    }else if(type=="number"){
        let res = parseFloat(val);
        return res
    }else if(type=="boolean"){
        if(val=="false"||val==false){
            return false;
        }else if(val=="true"||val==true){
            return true
        }else{
            throw new Error("Typeerror");
        }
    }else if(type=="datetime"){
        let res = moment(val).toDate();
        return res;
    }else if(type=="any"){
        return val;
    }else if(type=="file"){
        val._type = "file";
        return val;
    }
}

// test the schema parsing of objects and write the schema parsing of arrays for type arrays and objects
// handle defaults
export const parseSchema = async (schema, schemamapping,executablePipeline)=>{
    let queryval = {};
    for(let i =0; i < schema.length; i++){
        let keymappingindex = _.findIndex(schemamapping, (mm)=>{return mm.key==schema[i].key});
        let keymapping = schemamapping[keymappingindex].mapping;
        let val;
        if(schema[i].type=="object"){
            if(keymapping!=""){
                if(keymapping.action=="const"){
                    val = keymapping.val;
                }else if(keymapping.action=="get"){
                    val = await getValfromPipe(keymapping.val, executablePipeline);
                }
                else{
                    if(schema[i].subschema.length>0){
                        val = await parseSchema(schema[i].subschema,keymapping, executablePipeline);
                    }else{
                        val = {};
                    }
                }
            }else{
                val=undefined;
            }
            if((val==""||val==undefined)&&schema[i].default!=undefined){
                val = schema[i].default;
            }
            
        }else if(schema[i].type=="array"){
            val = [];
            if(keymapping!=""){
                if(keymapping.action=="const"){
                    if(schema[i].subschema[0].type=="object"){
                        let arrayval = [];
                        let submapping = keymapping.mapping;
                        for(let j=0; j < submapping.length; j++){
                            let indexval = await parseSchema(schema[i].subschema[0].subschema, submapping[j], executablePipeline);
                            arrayval.push(indexval)
                        }
                        val = arrayval;
                    }else if(schema[i].subschema[0].type=="array"){
                        let arrayval = [];
                        let submapping = keymapping.mapping;
                        for(let j=0; j < submapping.length; j++){
                            let indexval = await parseSchema(schema[i].subschema[0].subschema, submapping[j], executablePipeline);
                            arrayval.push(indexval)
                        }
                        val = arrayval;
                    }
                    else{
                        let submapping = keymapping.mapping;
                        let arrayval = [];
                        for(let j=0; j< submapping.length; j++){
                            if(submapping[j].action=="const"){
                                arrayval.push(getTypeObject(schema[i].subschema[0].type,submapping[j].val))
                            }else if(submapping[j].action=="get"){
                                let itemval = await getValfromPipe(submapping[j].val, executablePipeline);
                                arrayval.push(itemval);
                            }
                        }
                        val = arrayval;
                        
                    }
                     
                }else if(keymapping.action=="get"){
                    val = await getValfromPipe(keymapping.val, executablePipeline);
                }
                // default to the schema default if value is null
            }else{
                val=undefined
            }
            if((val==[]||val==undefined)&&Array.isPrototypeOf(schema[i].default)){
                val = schema[i].default;
            }
            
        }else if(schema[i].type=="string"||schema[i].type=="enum"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);
        }else if(schema[i].type=="text"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);
        }
        else if(schema[i].type=="file"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);
        }else if(schema[i].type=="integer"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);
        }
        else if(schema[i].type=="number"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);
        }else if(schema[i].type=="boolean"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);
        }else if(schema[i].type=="datetime"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);
        }else if(schema[i].type=="any"){
            val = await parsePrimitives(schema[i], keymapping, executablePipeline);  
        }else if(schema[i].type=="conditional"){
            if(keymapping!=""){
                if(schema[i].conditionalres){
                    let conditionalschema = schema[i].success;
                    if(conditionalschema.type=="object"){
                        val = await parseSchema(conditionalschema.subschema, keymapping, executablePipeline);
                    }else if(conditionalschema.type=="array"){
                        val = await parseSchema(conditionalschema.subschema, keymapping, executablePipeline);
                    }else{
                        val = await parsePrimitives(conditionalschema, keymapping, executablePipeline)
                    }
                }else{
                    let conditionalschema = schema[i].error;
                    if(conditionalschema.type=="object"){
                        val = await parseSchema(conditionalschema.subschema, keymapping, executablePipeline);
                    }else if(conditionalschema.type=="array"){
                        val = await parseSchema(conditionalschema.subschema, keymapping, executablePipeline);
                    }else{
                        val = await parsePrimitives(conditionalschema, keymapping, executablePipeline)
                    }
                }
            }else{
                val = undefined
            }
            if((val==""||val==undefined)&&schema[i].default!=undefined){
                val = schema[i].default;
            }
        }
        queryval[schema[i].key] = val
    }
    return queryval;
}

export const isStageResponse = (stage)=>{
    if(stage.action=="pipeline_response"){
        return true;
    }else if(stage.action=="response"){
        return true;
    }
    return false;
}

export const validatePrimaryTypes = (value, type, validationregex ,required)=>{
    if(type=="string"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        if(validationregex!=""){
            let regex = new RegExp(validationregex);
            // Return true if the str
            // matched the ReGex
            if (regex.test(value) == true) {
                return {
                    valid: true,
                    value: value
                };
            }else{
                return {
                    valid: false,
                    error: "Value doesnt match input pattern"
                };
            }
            
        }else{
            return {
                valid: true,
                value: value
            };
        }
        

    }else if(type=="text"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            }
        }
        return {
            valid: true,
            value: value
        };
    }else if(type=="number"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        let val = parseFloat(value);
        if(val==null||isNaN(val)){
            return {
                valid: false,
                error: "TypeError"
            }
        }
        return {
            valid: true,
            value: val
        };
    }else if(type=="integer"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            }
        }
        let val = parseInt(value);
        if(val==null||isNaN(val)){
            return {
                valid: false,
                error: "TypeError"
            }
        }
        return {
            valid: true,
            value: val
        };

    }else if(type=="boolean"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        if(value!="false"||value!="true"){
            return {
                valid: false,
                error: "TypeError"                
            }
        }
        return {
            valid: true,
            value: value
        };
    }else if(type=="datetime"){
        if(required&&value==""){
            return {
                valid: false,
                error: "Value cannot be empty"
            };
        }
        let valid = moment(value).isValid();
        if(valid){
            let val = moment(value).format();
            return {
                valid: true,
                value: val
            };
        }else{
            return {
                valid: false,
                error: "TypeError"
            };
        }
    }else if(type=="file"){
        return {
            valid: true
        }
    }
}

export const resetschemapping = (schemamapping)=>{
    for(let i=0; i< schemamapping.length; i++){
        if(Array.prototype.isPrototypeOf(schemamapping[i].mapping)){
            schemamapping[i].mapping = resetschemapping(schemamapping[i].mapping)
        }else{
            schemamapping[i].mapping = ""
        }
    }
    return schemamapping;
}

export const resetschema = (schema)=>{
    for(let i =0; i< schema.length; i++){
        schema[i].fillallowed = true;
        schema[i].guided = false;
        schema[i].guidefininshed = true;
        if(schema[i].subschema.length>0){
            schema[i].subschema = resetschema(schema[i].subschema);
        }
    }
    return schema;
}

export const isEventLoop = (app)=>{
    let systemLoops = ['http_server', 'scheduler', 'manual_trigger'];
    let systemLoopIndex = _.findIndex(systemLoops, (loop)=>{return loop==app});
    if(systemLoopIndex>-1){
        return true;
    }else{
        return false;
    }
}

export const updateOutput = (delineatedPipeline, stageindex,output,current_index)=>{
    let outputschemadetect = true;
    let outputschema = []; 
    if(outputschemadetect){
            generateSchema(output,outputschema)
    }
    if(current_index!=undefined){
        let loopval = output.array_to_loop[current_index];
        let loopschema = []
        generateSchema(loopval, loopschema);
        let currentIndexSchema = [{
            "key":"current_index",
            "label": "Current Index",
            "type": "object",
            "subschema": []
        }]
        if(loopschema[0].type=="object"){
            currentIndexSchema[0] = {...currentIndexSchema[0],
                                     subschema: loopschema,
                                     value: loopval
                                    }
        }else{
            currentIndexSchema[0] = {...currentIndexSchema[0],
                                     type: loopschema[0].type,
                                     value: loopval
                                    }
        }
        delineatedPipeline[stageindex] = {
                                            ...delineatedPipeline[stageindex],
                                            output: output,
                                            outputschema: outputschema,
                                            current_index: current_index,
                                            loopval: loopval,
                                            loopschema: currentIndexSchema,
                                            type: "value"
                                         }
    }else{
        delineatedPipeline[stageindex] = {  
                                            ...delineatedPipeline[stageindex],
                                            output: output,
                                            outputschema: outputschema,
                                            loopschema: [],
                                            type: "value"
                                         }
    }
}

export const updateError = (delineatedPipeline, stageindex, error)=>{
    delineatedPipeline[stageindex] = {...delineatedPipeline[stageindex],
                                      error: error
                                     }  
}

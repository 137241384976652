import { useState , useRef, useEffect} from "react"
import "./Output.css";
import { useSelector } from "react-redux";
import {getVariablePipeline} from "../../../Services/pipelines/pipeline.slice";
import * as _ from "lodash";
import { getLoopSchema, getOutputSchemaVal} from "../../utils";
import { 
            jsoncompare, 
            selectTemplateVariable, 
            selectLoopVariables, 
            selectLoopVariableV1, 
            selectInputVariables,
            selectRouteParams
         } from "../../../Services/apptemplates/apptemplates.slice";
import {InteractionSettings} from "../../../apps/appdesigner/Settings";
import { selectCurrentAppvar, selectCurrentWebsiteVar, selectCurrentWorkspacevar } from "../../../Services/environvariables/environvariables.slice";
import DisplayJson from "./DisplayJson";
// allow primitive types for schema...
const DataValueContainer = ({
                                sc,
                                type, 
                                setschemaMapping, 
                                getStageValue,
                                closeFieldDec, 
                                index,
                                subschematobefilled
                            })=>{

    const [hoveractive, setHoverActive] = useState(false);
    
    const matchType = (ltype, rtype)=>{
        if(ltype=="any"){
            return true
        }else if(ltype=="number"){
            if(rtype=="integer"||rtype=="number"){
                return true
            }else{
                return false
            }
        }else if(ltype=="text"){
            if(rtype=="text"||rtype=="string"){
                return true
            }else{
                return false
            }
        }else if(ltype=="string"){
            if(rtype=="text"||rtype=="string"){
                return true
            }else{
                return false
            }
        }else if(ltype=="auto"){
            if(rtype=="number"||rtype=="integer"){
                return true
            }else{
                return false
            }
        }
        else{
            if(ltype==rtype){
                return true
            }else{
                return false;
            }
        }
    }

    const matchObject = (schematomatch, schematomatchwith)=>{
        // if check to handle schemas of general type Object i.e Object with empty schemas
        if(schematomatch!=undefined){
            if(schematomatch.length==schematomatchwith.length){
                let valid = true;
                for(let j=0; j<schematomatch.length; j++){
                    let keyfound = _.findIndex(schematomatchwith, 
                                                    (sbc)=>{
                                                        let keymatch = sbc.key==schematomatch[j].key;
                                                        let typematch = matchType(sbc.type, schematomatch[j].type);
                                                        return keymatch&&typematch
                                                    }
                                                );
                    if(keyfound==-1){
                        valid = false;
                        break;
                    }
                    if(schematomatch[j].type=="object"||schematomatch[j].type=="array"){
                        let res = matchObject(schematomatch[j].subschema, schematomatchwith[keyfound].subschema);
                        if(res==false){
                            valid = false;
                            break;
                        }
                    }
                }
                return valid;
            }else{
                return false;
            }
        }else{
            return true;
        }
        
        
    }

    // implement type check here
    // deep equality check of schemas for the objects
    const isCompatible = ()=>{
        let typearr = type.split(",");
        let valid = false;
        if(sc.type=="any"){
            valid = true;
            return valid;
        }else{
            if(typearr.length>1){
                for(let i =0; i<typearr.length; i++){
                    if(type=="number"){
                        let val = getStageValue([index]);
                        let parsedVal = parseFloat(val);
                        if(parsedVal!=NaN){
                            valid = true
                        }else{
                            valid = false
                        }
                    }else if(type=="integer"||type=="auto"){
                        let val = getStageValue([index]);
                        let parsedVal = parseInt(val);
                        if(parsedVal!=NaN){
                            valid = true
                        }else{
                            valid = false
                        }
                    }else if(type=="text"){
                        if(sc.type=="string"||sc.type=="text"){
                            valid = true
                        }else{
                            valid = false
                        }
                    }else if(type=="string"){
                        if(sc.type=="string"||sc.type=="text"){
                            valid = true;
                        }else{
                            valid = false;
                        }
                    }
                    else if(type=="object"&&sc.type=="object"){
                        valid = matchObject(subschematobefilled, sc.subschema);
                    }else if(type=="array"&&sc.type=="array"){
                        valid = matchObject(subschematobefilled, sc.subschema);
                    }else{
                        if(sc.type==type||type=="any"){
                            valid = true;
                        }else{
                            valid = false;
                        }
                    }
                }
                return valid;
            }else{
                if(type=="number"){
                    let val = getStageValue([index]);
                    let parsedVal = parseFloat(val);
                    if(parsedVal!=NaN){
                        return true
                    }else{
                        return false
                    }
                }else if(type=="integer"||type=="auto"){
                    let val = getStageValue([index]);
                    let parsedVal = parseInt(val);
                    if(parsedVal!=NaN){
                        return true
                    }else{
                        return false
                    }
                }else if(type=="text"){
                    if(sc.type=="string"||sc.type=="text"){
                        return true
                    }else{
                        return false
                    }
                }else if(type=="string"){
                    if(sc.type=="string"||sc.type=="text"){
                        return true;
                    }else{
                        return false;
                    }
                }
                else if(type=="object"&&sc.type=="object"){
                    valid = matchObject(subschematobefilled, sc.subschema);
                    return valid;
                }else if(type=="array"&&sc.type=="array"){
                    valid = matchObject(subschematobefilled, sc.subschema);
                    return valid;
                }
                else{
                    if(sc.type==type||type=="any"){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        }
        
    }

    const getClassName = ()=>{
        if(hoveractive){
            let compatible = isCompatible();
            if(compatible){
                return "insert-output-data-tile active"
            }else{
                return "insert-output-data-tile active disabled"
            }
        }else{
            return "insert-output-data-tile"
        }
    }

    const mapSchema = ()=>{
        let compatible = isCompatible();
        if(compatible){
            let val = getStageValue([index]);
            setschemaMapping(val, sc.type);
            closeFieldDec(false);
        }
    }

    return (
        <>
            {sc.type=="arrayval"?
                <>
                    <div
                        className={hoveractive?getClassName():"insert-output-data-tile"} 
                        onMouseEnter={()=>{setHoverActive(true)}} 
                        onMouseLeave={()=>{setHoverActive(false)}}
                        onClick={()=>{mapSchema()}}
                    >
                        <div className="data-label">
                            {sc.label?sc.label:sc.key}
                        </div>
                        <div>
                            {sc?.subschema?.length>0?
                                <div className="data-type">
                                    {sc.type+" ["+sc.subschema[0].type+"]"}
                                </div>:
                                <div className="data-type">
                                    {sc.type}
                                </div>
                            }
                        </div>
                        <div>
                            {
                                hoveractive&&
                                <div>
                                    <DisplayJson
                                        val={sc.value}
                                    >
                                    </DisplayJson>
                                </div>
                            }
                        </div>
                    </div>
                </>:
                <>
                    {sc.type=="array"?
                        <>
                            <div 
                                className={hoveractive?getClassName():"insert-output-data-tile"} 
                                onMouseEnter={()=>{setHoverActive(true)}} 
                                onMouseLeave={()=>{setHoverActive(false)}}
                                onClick={()=>{mapSchema()}}
                            >
                                <div className="data-label">
                                    {sc.label?sc.label:sc.key}
                                </div>
                                <div>
                                    {sc?.subschema?.length>0?
                                        <div className="data-type">
                                            {sc.type+" ["+sc.subschema[0].type+"]"}
                                        </div>:
                                        <div className="data-type">
                                            {sc.type}
                                        </div>
                                    }
                                </div>
                                <div>
                                {hoveractive&&
                                    <DisplayJson
                                        val={sc.value}
                                    >
                                    </DisplayJson>    
                                }
                                </div>
                            </div>
                        </>:
                        <>
                            <div 
                                className={hoveractive?getClassName():"insert-output-data-tile"} 
                                onMouseEnter={()=>{setHoverActive(true)}} 
                                onMouseLeave={()=>{setHoverActive(false)}}
                                onClick={()=>{mapSchema()}}
                            >
                                <div className="data-label">
                                    {sc.label?sc.label:sc.key}
                                </div>
                                <div>
                                    <div className="data-type">
                                        {sc.type}
                                    </div>
                                </div>
                                <div>
                                {hoveractive&&
                                    <div>
                                        {sc.type=="file"?
                                            <DisplayJson
                                                val={sc.value}
                                            >
                                            </DisplayJson>
                                            :
                                            <>
                                                {(sc.type=="object")?
                                                    <DisplayJson
                                                        val={sc.value}
                                                    >
                                                    </DisplayJson>
                                                    :
                                                    <pre>
                                                        {sc.value}
                                                    </pre>
                                                }
                                            </>
                                        }
                                    </div>
                                }
                                </div>
                            </div>
                        </>
                    }
                </>

            }
        </>
    )
}   

export const InsertSchemaContainer = ({
        schema,
        type, 
        getStageValue,
        setschemaMapping,
        closeFieldDec,
        subschematobefilled
    })=>{

    const getstagevalueinternal = (i)=>{
        return (indexarray)=>{
            return getStageValue([i, ...indexarray]);
        }
    }

    return (
        <div>
            {schema?.map((sc,i)=>{
                return(
                    <>
                        <div style={{"margin-left": "5px"}}>
                            {sc!=undefined&&
                                <>
                                    {sc.type=="array"?
                                        <div>
                                            <div className="insert-data-tile" onClick={()=>{}}>
                                                <DataValueContainer 
                                                    sc={sc} 
                                                    type={type}
                                                    index={i}
                                                    getStageValue={getStageValue}
                                                    setschemaMapping={setschemaMapping}
                                                    closeFieldDec={closeFieldDec}
                                                    subschematobefilled={subschematobefilled}
                                                >
                                                </DataValueContainer>
                                            </div>
                                            {/* {sc?.subschema?.length>0&&
                                                <>
                                                    {sc.subschema[0].type=="object"&&
                                                        <InsertSchemaContainer 
                                                            schema={sc.subschema[0].subschema} 
                                                            type={type}
                                                            closeFieldDec={closeFieldDec}
                                                        ></InsertSchemaContainer>
                                                    }
                                                </>
                                            } */}
                                        </div>:
                                        <div>
                                            <div className="insert-data-tile">
                                                <DataValueContainer 
                                                    sc={sc} 
                                                    type={type} 
                                                    getStageValue={getStageValue}
                                                    loopschema    
                                                    setschemaMapping={setschemaMapping}
                                                    index={i}
                                                    closeFieldDec={closeFieldDec}
                                                    subschematobefilled={subschematobefilled}
                                                >
                                                </DataValueContainer>
                                            </div>
                                            <InsertSchemaContainer 
                                                schema={sc.subschema} 
                                                type={type}
                                                getStageValue={getstagevalueinternal(i)}
                                                setschemaMapping={setschemaMapping}
                                                closeFieldDec={closeFieldDec}
                                                subschematobefilled={subschematobefilled}
                                            ></InsertSchemaContainer>
                                        </div>
                                    }
                                </>
                            }
                            
                            
                        </div>
                    </>
                )
            })}
        </div>
    )
}

export const ErrorSchemaContainer = ({
    error,
    type,
    getStageValue,
    setschemaMapping,
    closeFieldDec,
    subschematobefilled
})=>{

    return (
        <div>
            <DataValueContainer>
                
            </DataValueContainer>
        </div>
    )
}

const InsertStageContainer = ({
                                stage,
                                type, 
                                setschemaMapping,
                                closeFieldDec,
                                pipelinetype,
                                subschematobefilled
                              })=>{
    
    const [open, setOpen] = useState(false);

    const getStageValue = (iarray)=>{
            let indexarray = [...iarray];
            let schemaval = "";
            let value = "";
            let app = stage;
            if(app.type=="conditional"||app.type=="loop"){
                schemaval = schemaval+app.app+"["+app.stageindex+"]";
                let schema = app.blockschema;
                for(let i=0; i< indexarray.length; i++){
                    schemaval = schemaval+"."+schema[indexarray[i]].key;
                    value = schema[indexarray[i]].value;
                    schema = schema[indexarray[i]].subschema;
                }
            }else{
                schemaval = schemaval+app.app+"["+app.stageindex+"]";
                let schema = getOutputSchemaVal(stage.stageindex);
                for(let i=0; i< indexarray.length; i++){
                    schemaval = schemaval+"."+schema[indexarray[i]].key;
                    value = schema[indexarray[i]].value;
                    schema = schema[indexarray[i]].subschema;
                }
            }
            return {schemaval:schemaval, value:value};
    }

    const getCurrentIndex = (iarray)=>{
        let indexarr = [...iarray];
        let app = stage;
        let schemaval="";
        let value = "";
        schemaval = schemaval+app.app+"["+app.stageindex+"]";
        let schema = getLoopSchema(stage.stageindex);
        for(let i=0; i< indexarr.length; i++){
            schemaval = schemaval+"."+schema[indexarr[i]].key;
            value = schema[indexarr[i]].value;
            schema = schema[indexarr[i]].subschema;
        }
        return {schemaval: schemaval, value: value}
    }

    return(
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        {stage.stageindex}. {stage.app}
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <>
                    {stage.type=="single"&&
                        <InsertSchemaContainer 
                            schema={getOutputSchemaVal(stage.stageindex)} 
                            type={type} 
                            getStageValue={getStageValue} 
                            setschemaMapping={setschemaMapping}
                            closeFieldDec={closeFieldDec}
                            subschematobefilled={subschematobefilled}
                        ></InsertSchemaContainer>        
                    }
                    {stage.active&&stage.type=="loop"&&
                        <>
                            <InsertSchemaContainer
                                schema={getLoopSchema(stage.stageindex)}
                                type={type}
                                getStageValue={getCurrentIndex}
                                setschemaMapping={setschemaMapping}
                                closeFieldDec={closeFieldDec}
                                subschematobefilled={subschematobefilled}
                            >
                            </InsertSchemaContainer>
                        </>
                    }
                </>
            }
        </div>
    )
}

const TemplateVariables = ({
    templateid,
    setschemaMapping,
    closeFieldDec,
    type,
    subschematobefilled

})=>{

    const templateVariable = useSelector((state)=>selectTemplateVariable(state, templateid));

    const [open, setOpen] = useState(false);
    
    const getStageValue = (iarray)=>{
        let indexarray = [...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"template"+"[template]";
        let schema = templateVariable.schema;
        for(let i=0; i< indexarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval:schemaval, value:value};
    }

    const getSchema = ()=>{
        if(templateVariable==undefined){
            return [];
        }else{
            return templateVariable.schema;
        }
    }

    return (
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        Template Variables
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <InsertSchemaContainer
                    schema={getSchema()} 
                    type={type} 
                    getStageValue={getStageValue} 
                    setschemaMapping={setschemaMapping}
                    closeFieldDec={closeFieldDec}
                    subschematobefilled={subschematobefilled}
                >
                </InsertSchemaContainer>
            
            }
        </div>
    )
}

const RouteParamsContainer = ({
    templateid,
    setschemaMapping,
    closeFieldDec,
    type,
    subschematobefilled
})=>{

    const routeParams = useSelector((state)=>selectRouteParams(state, templateid));

    const [open, setOpen] = useState(false);

    const getStageValue = (iarray)=>{
        let indexarray = [...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"routeparams"+"[template]";
        let schema = routeParams.schema;
        for(let i=0; i< indexarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval:schemaval, value:value};
    }

    const getSchema = ()=>{
        if(routeParams==undefined){
            return [];
        }else{
            return routeParams.schema;
        }
    }

    return (
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        Route Params
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <InsertSchemaContainer
                    schema={getSchema()} 
                    type={type} 
                    getStageValue={getStageValue} 
                    setschemaMapping={setschemaMapping}
                    closeFieldDec={closeFieldDec}
                    subschematobefilled={subschematobefilled}
                >
                </InsertSchemaContainer>
            
            }
        </div>
    )
}

const TemplateLoopVariables = ({
            templateid,
            setschemaMapping,
            closeFieldDec,
            type,
            subschematobefilled,
            indexarr
})=>{

    const loopvar = useSelector(selectLoopVariableV1(templateid, indexarr));

    const [open, setOpen] = useState(false);

    const getStageValue = (iarray)=>{
        let indexarray = [...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"loopvar"+"[template]";
        let schema = loopvar;
        for(let i=0; i< indexarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval: schemaval, value: value}
    }

    const getSchema = ()=>{

    }

    return (
        <>
            {loopvar.length>0&&
                <div>
                    <div className="insert-data-tile-header">
                        <div className="insert-data-value-tab">
                            <div className="insert-data">
                                Loop Variables
                            </div>
                        </div>
                        <div>
                            {open?
                                <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                                    <span class="material-symbols-outlined">
                                        expand_less
                                    </span>
                                </div>:
                                <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                                    <span class="material-symbols-outlined">
                                        expand_more
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    {open&&
                        <InsertSchemaContainer
                            schema={loopvar} 
                            type={type} 
                            getStageValue={getStageValue} 
                            setschemaMapping={setschemaMapping}
                            closeFieldDec={closeFieldDec}
                            subschematobefilled={subschematobefilled}
                        >
                        </InsertSchemaContainer>
                    
                    }
                </div>
            
            }
        </>
    )
}

const EventContainer = ({
    eventname,
    setschemaMapping,
    closeFieldDec,
    type,
    subschematobefilled
})=>{

    const [open, setOpen] = useState(false);

    const addSampleValue = (schema)=>{
        for(let i=0; i< schema.length; i++){
            if(schema[i].subschema.length>0){
                addSampleValue(schema[i]);
            }
            schema[i] = {...schema[i],
                         value: ""
                        }
        }
    }

    const getSchema = ()=>{
        if(eventname==""||eventname==undefined||eventname==null){
            return [];
        }else{
            let interactionIndex = _.findIndex(InteractionSettings, (interact)=>{ return interact.name==eventname});
            if(interactionIndex>-1){
                let interactionSchema = [...InteractionSettings[interactionIndex].properties];
                addSampleValue(interactionSchema);
                return interactionSchema;
            }else{
                return [];
            }
        }
    }


    const getStageValue = (iarray)=>{
        let indexarray = [...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"event"+"["+eventname+"]";
        let schema = getSchema();
        for(let i=0; i< indexarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval:schemaval, value:value};
    }

    return (
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        Template Variables
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <InsertSchemaContainer
                    schema={getSchema()} 
                    type={type} 
                    getStageValue={getStageValue} 
                    setschemaMapping={setschemaMapping}
                    closeFieldDec={closeFieldDec}
                    subschematobefilled={subschematobefilled}
                >
                </InsertSchemaContainer>
            
            }
        </div>
    )
}

const InputContainer = ({
    templateid,
    setschemaMapping,
    closeFieldDec,
    type,
    subschematobefilled
})=>{

    const [open, setOpen] = useState(false);

    const inputVariable = useSelector(selectInputVariables(templateid));

    const getStageValue = (iarray)=>{
        let indexarray = [...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"inputs"+"[template]";
        let schema = inputVariable;
        for(let i=0; i< indexarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval:schemaval, value:value};
    }

    const getSchema = ()=>{
        if(inputVariable==undefined){
            return [];
        }else{
            return inputVariable;
        }
    }
    return (
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        Input Variables
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <InsertSchemaContainer
                    schema={getSchema()} 
                    type={type} 
                    getStageValue={getStageValue} 
                    setschemaMapping={setschemaMapping}
                    closeFieldDec={closeFieldDec}
                    subschematobefilled={subschematobefilled}
                >
                </InsertSchemaContainer>
            
            }
        </div>
    )


}

const LocalStorageContainer = ({
    templateid,
    setschemaMapping,
    closeFieldDec,
    type,
    subschematobefilled
})=>{

    const [open, setOpen] = useState(false);

    const getStageValue = (iarray)=>{
        let indexarray = [...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"localstorage"+"[template]";
        let schema = getSchema();
        for(let i=0; i< iarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval: schemaval, value: value}
    }

    const getSchema = ()=>{
        let schema = [{
            "key": "localstorage",
            "label": "LocalStorage",
            "type": "object",
            "subschema":[],
            "value":{}
        }];
        let subschema = [];
        let lStorage = {};
        let clientid = localStorage.getItem("clientid");
        for(let i=0; i< localStorage.length; i++){
            let storagekey = localStorage.key(i);
            if(storagekey.startsWith(clientid+"__")){
                let storagekeyvalue = localStorage.getItem(storagekey);
                let keyschema = {
                    "key": storagekey,
                    "label": storagekey,
                    "type": "string",
                    "value": storagekeyvalue,
                    "subschema":[]
                }
                subschema.push(keyschema);
                lStorage[storagekey] = storagekeyvalue
            }
        }
        if(subschema.length>0){
            schema[0] = {...schema[0],
                         subschema: subschema,
                         value: lStorage
                        }
            return schema;
        }else{
            return []
        }
    }

    return (
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        Local Storage
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <InsertSchemaContainer
                    schema={getSchema()} 
                    type={type} 
                    getStageValue={getStageValue} 
                    setschemaMapping={setschemaMapping}
                    closeFieldDec={closeFieldDec}
                    subschematobefilled={subschematobefilled}
                >
                </InsertSchemaContainer>       
            }
        </div>
    )
}

const SessionStorageContainer = ({
    templateid,
    setschemaMapping,
    closeFieldDec,
    type,
    subschematobefilled
})=>{

    const [open, setOpen] = useState(false);

    const getStageValue = (iarray)=>{
        let indexarray = [...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"sessionstorage"+"[template]";
        let schema = getSchema();
        for(let i=0; i< iarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval: schemaval, value: value}
    }

    const getSchema = ()=>{
        let schema = [{
            "key": "sessionstorage",
            "label": "SessionStorage",
            "type": "object",
            "subschema":[],
            "value":{}
        }];
        let subschema = [];
        let lStorage = {};
        for(let i=0; i< sessionStorage.length; i++){
            let storagekey = sessionStorage.key(i);
            if(storagekey.startsWith(templateid)){
                let storagekeyvalue = sessionStorage.getItem(storagekey);
                let keyschema = {
                    "key": storagekey,
                    "label": storagekey,
                    "type": "string",
                    "value": storagekeyvalue,
                    "subschema":[]
                }
                subschema.push(keyschema);
                lStorage[storagekey] = storagekeyvalue
            }
        }
        if(subschema.length>0){
            schema[0] = {...schema[0],
                         subschema: subschema,
                         value: lStorage
                        }
            return schema;
        }else{
            return []
        }
    }

    return (
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        Session Storage
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <InsertSchemaContainer
                    schema={getSchema()} 
                    type={type} 
                    getStageValue={getStageValue} 
                    setschemaMapping={setschemaMapping}
                    closeFieldDec={closeFieldDec}
                    subschematobefilled={subschematobefilled}
                >
                </InsertSchemaContainer>
            
            }
        </div>
    )
}

const TemplatePipelinesContainer = ({
    templateid,
    setschemaMapping,
    closeFieldDec,
    type,
    subschematobefilled
})=>{


    return (
        <>
        </>
    )

}

const EnvironContainer = ({
    source,
    setschemaMapping,
    closeFieldDec,
    subschematobefilled,
    type
})=>{

    const [open, setOpen] = useState(false);

    const [environschema, setenvrionschema] = useState([
        {
            "key": "___envvar___",
            "type": "object",
            "label": "___envvar___",
            "subschema":[
                {
                    "key": "workspace",
                    "type": "object",
                    "label": "workspace",
                    "subschema":[]
                },
                {
                    "key": "app",
                    "type": "object",
                    "label": "app",
                    "subschema": []
                },
                {
                    "key": "website",
                    "type": "object",
                    "label": "website",
                    "subschema":[]
                }
            ]
        }
    ]);

    const currentworkspacevar = useSelector(selectCurrentWorkspacevar);
    const currentwebsitevar = useSelector(selectCurrentWebsiteVar);
    const currentappvar = useSelector(selectCurrentAppvar);

    // const {schemaid} = useParams();

    useEffect(()=>{
        if(source=="apps"){
            let environschemacopy = [...environschema]
            if(currentworkspacevar.length>0){
                let workspaceenvar = [];
                for(let i=0; i< currentworkspacevar.length; i++){
                    if(
                        currentworkspacevar[i].type=="string"||
                        currentworkspacevar[i].type=="number"||
                        currentworkspacevar[i].type=="boolean"||
                        currentworkspacevar[i].type=="datetime"
                    ){
                        workspaceenvar.push({
                            "key": currentworkspacevar[i].name,
                            "value": currentworkspacevar[i].value,
                            "label": currentworkspacevar[i].name,
                            "type": currentworkspacevar[i].type
                        })
                    }
                }
                
                let subschema = [...environschemacopy[0].subschema];
                subschema[0] = {...subschema[0],
                                subschema: workspaceenvar
                               }
                environschemacopy[0] = {...environschemacopy[0],
                                        subschema: subschema
                                        }
            }

            if(currentappvar.length>0){
                let appenvar = [];
                for(let i=0; i<currentappvar.length; i++){
                    if(
                        currentappvar[i].type=="string"||
                        currentappvar[i].type=="number"||
                        currentappvar[i].type=="boolean"||
                        currentappvar[i].type=="datetime"
                    ){
                        appenvar.push({
                            "key": currentappvar[i].name,
                            "value": currentappvar[i].value,
                            "label": currentappvar[i].name,
                            "type": currentappvar[i].type
                        })
                    }
                }
                let subschema = [...environschemacopy[0].subschema];
                subschema[1] = {...subschema[1],
                                subschema: appenvar
                               }
                environschemacopy[0] = {...environschemacopy[0],
                                        subschema: subschema
                                        }
            }

            setenvrionschema(environschemacopy);
        }

        if(source=="apptemplates"){
            let environschemacopy = [...environschema];
            if(currentworkspacevar.length>0){
                let workspaceenvar = [];
                for(let i=0; i< currentworkspacevar.length; i++){
                    if(
                        currentworkspacevar[i].type=="string"||
                        currentworkspacevar[i].type=="number"||
                        currentworkspacevar[i].type=="boolean"||
                        currentworkspacevar[i].type=="datetime"
                    ){
                        workspaceenvar.push({
                            "key": currentworkspacevar[i].name,
                            "value": currentworkspacevar[i].value,
                            "label": currentworkspacevar[i].name,
                            "type": currentworkspacevar[i].type
                        })
                    }
                }
                
                let subschema = [...environschemacopy[0].subschema];
                subschema[0] = {...subschema[0],
                                subschema: workspaceenvar
                               }
                environschemacopy[0] = {...environschemacopy[0],
                                        subschema: subschema
                                        }
            }

            if(currentwebsitevar.length>0){
                let appenvar = [];
                for(let i=0; i<currentwebsitevar.length; i++){
                    if(
                        currentwebsitevar[i].type=="string"||
                        currentwebsitevar[i].type=="number"||
                        currentwebsitevar[i].type=="boolean"||
                        currentwebsitevar[i].type=="datetime"
                    ){
                        appenvar.push({
                            "key": currentwebsitevar[i].name,
                            "value": currentwebsitevar[i].value,
                            "label": currentwebsitevar[i].name,
                            "type": currentwebsitevar[i].type
                        })
                    }
                }
                let subschema = [...environschemacopy[0].subschema];
                subschema[1] = {...subschema[1],
                                subschema: appenvar
                               }
                environschemacopy[0] = {...environschemacopy[0],
                                        subschema: subschema
                                        }
            }
            setenvrionschema(environschemacopy);
        }
    },[currentworkspacevar, currentwebsitevar, currentappvar, source])


    const getStageValue = (iarray)=>{
        let indexarray = [0,...iarray];
        let schemaval = "";
        let value = "";
        schemaval = schemaval+"[___envvar___]";
        let schema = [...environschema];
        for(let i=0; i< indexarray.length; i++){
            schemaval = schemaval+"."+schema[indexarray[i]].key;
            value = schema[indexarray[i]].value;
            schema = schema[indexarray[i]].subschema;
        }
        return {schemaval: schemaval, value: value}


    }


    return (
        <div>
            <div className="insert-data-tile-header">
                <div className="insert-data-value-tab">
                    <div className="insert-data">
                        ___envvar___
                    </div>
                </div>
                <div>
                    {open?
                        <div onClick={()=>{setOpen(false)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_less
                            </span>
                        </div>:
                        <div onClick={()=>{setOpen(true)}} style={{"cursor": "pointer"}}>
                            <span class="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>
                    }
                </div>
            </div>
            {open&&
                <InsertSchemaContainer
                    schema={environschema[0].subschema} 
                    type={type} 
                    getStageValue={getStageValue} 
                    setschemaMapping={setschemaMapping}
                    closeFieldDec={closeFieldDec}
                    subschematobefilled={subschematobefilled}
                >
                </InsertSchemaContainer>       
            }
        </div>
    )


}

const InstallManifestEnvironVariable = ({

})=>{

        

    return (
        <div>
        </div>
    )
}

const PipelineContainer = ({
    type,
    setschemaMapping,
    mapping,
    pipelinestage,
    pipelinetype,
    subschematobefilled,
    closeFieldSelector
})=>{


    const pipeline =   useSelector((state)=>getVariablePipeline(state,pipelinestage));

    return (
        <>
            {pipeline.map((stage,i)=>{
                return(
                    <>
                        {stage.type!=="internal"&&
                            <div>
                                <InsertStageContainer 
                                    stage={stage} 
                                    type={type}
                                    setschemaMapping={setschemaMapping}
                                    closeFieldDec = {closeFieldSelector}
                                    pipelinetype={pipelinetype}
                                    subschematobefilled={subschematobefilled}
                                ></InsertStageContainer>
                            </div>
                        }
                    </>
                )
            })}
            
        </>
    )
}

const Output = ({
                    type,
                    setschemaMapping, 
                    closeFieldSelector, 
                    mapping, 
                    pipelinestage, 
                    pipelinetype,
                    subschematobefilled,
                    source,
                    templateid,
                    eventname,
                    indexarr
                })=>{
    
    const [output,setOutput] = useState([]);

    const ref = useRef(null);


    return (
        <div className="output-container" ref={ref}>
                <div className="insert-data-outer-container">
                        <div className="insert-data-container">
                            <div className="insert-data-container-header">
                                <div style={{"cursor": "pointer"}} onClick={()=>{closeFieldSelector(false)}}>
                                    <span class="material-symbols-outlined">
                                        close
                                    </span>
                                </div>
                            </div>
                            {(source=="apps"||source=="installmanifest")&&
                                <>
                                    <EnvironContainer
                                        type={type}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        mapping={mapping}
                                        pipelinestage={pipelinestage}
                                        subschematobefilled={subschematobefilled}
                                        source={source}
                                    
                                    >
                                    </EnvironContainer>
                                    <PipelineContainer
                                        type={type}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldSelector={closeFieldSelector}
                                        mapping={mapping}
                                        pipelinestage={pipelinestage}
                                        pipelinetype={pipelinetype}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </PipelineContainer>
                                </>
                            }
                            {source=="apptemplates"&&
                                <>
                                    
                                    <PipelineContainer
                                        type={type}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldSelector={closeFieldSelector}
                                        mapping={mapping}
                                        pipelinestage={pipelinestage}
                                        pipelinetype={pipelinetype}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </PipelineContainer>
                                    <TemplateVariables
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </TemplateVariables>
                                    <InputContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </InputContainer>
                                    <RouteParamsContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </RouteParamsContainer>
                                    <LocalStorageContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >

                                    </LocalStorageContainer>
                                    {/* <SessionStorageContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </SessionStorageContainer> */}
                                </>
                            }
                            {source=="appprops"&&
                                <>
                                    <TemplateVariables
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </TemplateVariables>
                                    <TemplateLoopVariables
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                        indexarr={indexarr}
                                    >
                                    </TemplateLoopVariables>
                                    <InputContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </InputContainer>
                                    <RouteParamsContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </RouteParamsContainer>
                                    <LocalStorageContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </LocalStorageContainer>
                                    <SessionStorageContainer
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </SessionStorageContainer>
                                </>
                            }
                            {source=="appinteractions"&&
                                <>
                                    <EventContainer
                                        eventname={eventname}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </EventContainer>
                                    <TemplateVariables
                                        templateid={templateid}
                                        setschemaMapping={setschemaMapping}
                                        closeFieldDec={closeFieldSelector}
                                        type={type}
                                        subschematobefilled={subschematobefilled}
                                    >
                                    </TemplateVariables>
                                </>
                            }
                        </div>
                </div>
        </div>
    )
}

export default Output;
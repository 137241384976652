import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { 
        filesInFolder, 
        selectFolderfiles, 
        deleteFile, 
        resetfiledeleted, 
        selectFileDeleted, 
        selectFileFolderpermission, 
        selectTotalfiles,
        filesInFolderCount
       } from "../../Services/filesystem/filesystem.slice";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { getValfromPipe } from "../utils";
import { createFile, resetfilecreated, selectFileCreatedRes } from "../../Services/filesystem/filesystem.slice";
import { useParams } from "react-router-dom";
import "./FolderView.css";

const GridView = ()=>{
    
}


const FolderView = ({
                        path, 
                        folderId,
                        handleClose,
                        pipelinemode,
                        setSchemaMappingData,
                        schemaMapping,
                        pipelinestage
                    })=>{

    const dispatch = useDispatch();

    const {workspace} = useParams();

    const [files, setFiles] = useState([]);

    const [fileval, setFileVal] = useState("");

    const [fileloading, setfileloading] = useState(false);

    const remotefiles = useSelector(selectFolderfiles);

    const filedeleted = useSelector(selectFileDeleted);

    const [deleteClicked, setDeleteClicked] = useState(false);

    const [sortActive, setSortActive] = useState("");

    const [sortDirection, setSortDirection] = useState(1);

    const [start, setStart] = useState(0);

    const [limit, setLimit] = useState(20);

    const total = useSelector(selectTotalfiles);

    const [init, setInit] = useState(false);


    const changeSortActive = (val)=>{
        if(val!=sortActive){
            setSortActive(val);
            setSortDirection(1)
        }else{
            if(sortDirection==-1){
                setSortDirection(1);
            }else{
                setSortDirection(-1);
            }
        }
    }

    const deleteFileAction = (id)=>{
        dispatch(resetfiledeleted());
        setfileloading(true);
        setDeleteClicked(true);
        dispatch(deleteFile(id));
    }

    useEffect(()=>{
        if((total-(start*limit+limit))>0){
            dispatch(
                filesInFolder(
                                {
                                    path: path,
                                    workspace: workspace,
                                    limit: limit,
                                    start: start*limit,
                                    sortActive: sortActive,
                                    sortDirection: sortDirection
                                }
                          )
            )

        }else{
            dispatch(
                filesInFolder({
                    path: path,
                    workspace: workspace,
                    limit: (total-start*limit),
                    start: start*limit,
                    sortActive: sortActive,
                    sortDirection: sortDirection
                })
            )
        }
        setfileloading(true);
    },[total])

    useEffect(()=>{
        if(init){
            if((total-(start*limit+limit))>0){
                dispatch(
                    filesInFolder(
                                    {
                                        path: path,
                                        workspace: workspace,
                                        limit: limit,
                                        start: start*limit,
                                        sortActive: sortActive,
                                        sortDirection: sortDirection
                                    }
                              )
                )

            }else{
                dispatch(
                    filesInFolder({
                        path: path,
                        workspace: workspace,
                        limit: (total-start*limit),
                        start: start*limit,
                        sortActive: sortActive,
                        sortDirection: sortDirection
                    })
                )
            }
            setfileloading(true);
        }
    },[limit, start, sortActive, sortDirection])

    useEffect(()=>{
        if(path==undefined||path==""||path==null){
            return;
        }
        /*
         todo: write api for fetching files that priotize pagination for the selected file
        */
        if(pipelinestage){
            dispatch(
                        filesInFolderCount(
                                            {
                                                path: path,
                                                workspace: workspace
                                            }
                                        )
                    )
        }else{
            dispatch(
                        filesInFolderCount(
                                            {
                                                path: path,
                                                workspace: workspace
                                            }
                                          )
                    )
        }
        setfileloading(true);
        setInit(true);
    },[path])

    useEffect(()=>{
        if(filedeleted){
            dispatch(
                        filesInFolderCount(
                                            {
                                                path: path,
                                                workspace: workspace
                                            }
                                          )
                    )
            setfileloading(true);
        }
    },[deleteClicked, filedeleted])

    const getChecked = (url)=>{
        if(schemaMapping.mapping.inputtype==2){
            let val = schemaMapping.mapping.val;
            if(val.url==url){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    const selectFile = (file, checked)=>{
        if(checked){
            setSchemaMappingData(file)
        }else{
            setSchemaMappingData("");
        }
    }

    const handleChange = (e)=>{
        let file = e.target.files[0];
        dispatch(resetfilecreated());
        setfileloading(true);
        dispatch(createFile({
                "file": file,
                "path": path,
                "workspace": workspace
            }))
    }

    const fileuploadres = useSelector(selectFileCreatedRes);

    const filefolderpermission = useSelector(selectFileFolderpermission);

    useEffect(()=>{
        if(init){
            dispatch( 
                filesInFolderCount(
                                    {
                                        path: path,
                                        workspace: workspace
                                    }
                                  )
            )
            setFileVal("")
        }
    },[fileuploadres])

    useEffect(()=>{
        if(remotefiles.length>0){
            let remotefilesCopy = [...remotefiles];
            setFiles(remotefilesCopy);
        }
        if(fileloading==true){
            setfileloading(false);
        }
    },[remotefiles])

    return (
        <div>
            {fileloading&&
                <LinearProgress></LinearProgress>
            }
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                {filefolderpermission=="write"&&
                    <input type="file" value={fileval} onChange={(e)=>{handleChange(e)}}></input>
                }
                <IconButton onClick={()=>{handleClose()}}>
                    <span className="material-symbols-outlined">
                        close
                    </span>
                </IconButton>
            </div>
            <div>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <div className="sort-table-headers" onClick={()=>{changeSortActive("name")}}>
                                    <div>
                                        Name
                                    </div>
                                        {sortActive=="name"&&
                                            <>
                                                {sortDirection==1?
                                                    
                                                        <span className="material-symbols-outlined">
                                                            arrow_downward
                                                        </span>
                                                    :
                                                    
                                                        <span className="material-symbols-outlined">
                                                            arrow_upward
                                                        </span>
                                                }
                                            </>
                                        }
                                </div>
                            </TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>
                                <div className="sort-table-headers" onClick={()=>{changeSortActive("size")}}>
                                    <div>
                                        Size
                                    </div>
                                    {sortActive=="size"&&
                                        <>
                                            {sortDirection==1?
                                                    <span className="material-symbols-outlined">
                                                        arrow_downward
                                                    </span>
                                                :
                                                    <span className="material-symbols-outlined">
                                                        arrow_upward
                                                    </span>
                                            }
                                        </>
                                    }
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className="sort-table-headers" onClick={()=>{changeSortActive("lastmodified")}}>
                                    <div>
                                        LastModified
                                    </div>
                                    {sortActive=="lastmodified"&&
                                        <>
                                            {sortDirection==1?
                                                    <span className="material-symbols-outlined">
                                                        arrow_downward
                                                    </span>
                                                :
                                                    <span className="material-symbols-outlined">
                                                        arrow_upward
                                                    </span>
                                            }
                                        </>
                                    }
                                </div>        
                            </TableCell>
                            {pipelinemode?
                             <TableCell>
                                Select
                             </TableCell>:
                             <TableCell>
                                Actions
                             </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files!=undefined&&
                            <>
                                {files.map((file)=>{
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                {file.name}
                                            </TableCell>
                                            <TableCell>
                                                {file.url}
                                            </TableCell>
                                            <TableCell>
                                                {file.size}
                                            </TableCell>
                                            <TableCell>
                                                {file.lastModified}
                                            </TableCell>
                                            <TableCell>
                                                {pipelinemode?
                                                    <>
                                                        <input type="checkbox" checked={getChecked(file.url)} onChange={(e)=>{selectFile(file, e.target.value)}}></input>
                                                    </>:
                                                    <>
                                                        {filefolderpermission=="write"&&
                                                            <IconButton onClick={()=>{deleteFileAction(file._id)}}>
                                                                <span className="material-symbols-outlined">
                                                                    delete
                                                                </span>
                                                            </IconButton>
                                                        }
                                                    </>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}    
                            </>
                        }
                        
                    </TableBody>
                </Table>
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div>
                    <select
                        value={limit}
                        onChange={(e)=>{
                            setLimit(e.target.value)
                        }}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {start!=0&&
                    <div>  
                        <IconButton onClick={()=>{setStart(start-1)}}>
                            <span className="material-symbols-outlined">
                                chevron_left      
                            </span>
                        </IconButton>
                    </div>
                }
                <div>
                    {}
                </div>
                {(total-(start*limit+limit))>0&&
                    <div>
                        <IconButton onClick={()=>{setStart(start+1)}}>
                            <span className="material-symbols-outlined">
                                chevron_right
                            </span>
                        </IconButton>
                    </div>    
                }
            </div>
        </div>
    )
}


export default FolderView;
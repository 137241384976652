import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createFolderApi, deleteFolderApi, filesInFolderApi, getFoldersApi, createFileApi, deleteFileApi, getWriteFoldersApi, changePrivacyApi, filesInFolderCountApi } from "./filesystem.service";
import { showError } from "../error.slice";

export const getFolders = createAsyncThunk(
    "filesystem/getfolders",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getFoldersApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }

    }
)

export const getWriteFolders = createAsyncThunk(
    "filesystem/getwritefolders",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWriteFoldersApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting folders"));
            throw error;
        }
    }
)

export const createFolder = createAsyncThunk(
    "filesystem/createfolder",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createFolderApi(payload);
            dispatch(getFolders(payload.workspace));
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
        }
    }
)

/*
 move to websocket when implemented
*/
export const deleteFolder = createAsyncThunk(
    "filesystem/deletefolder",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteFolderApi(payload.id);
            dispatch(showError("Folder deleted successfully"));
            dispatch(getFolders(payload.workspace));
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)


/*
 implement pagination
*/
export const filesInFolder  = createAsyncThunk(
    "filesystem/filesinfolder",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await filesInFolderApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const filesInFolderCount = createAsyncThunk(
    "filesystem/filesinfoldercount",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await filesInFolderCountApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }

)

export const createFile = createAsyncThunk(
    "filesystem/createfile",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await createFileApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const deleteFile = createAsyncThunk(
    "filesystem/deletefile",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await deleteFileApi(payload);
            return res;
        }catch(error){
            dispatch(showError(error.response.data.error.message));
            throw error;
        }
    }
)

export const changePrivacy = createAsyncThunk(
    "filesystem/changeprivacy",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await changePrivacyApi(payload);
            dispatch(getFolders(payload.workspace));
            dispatch(showError("Privacy changed of the folder"));
            return res;
        }catch(error){
            dispatch(showError("Error changing privacy of folder"));
            throw error;
        }
    }
)


const filesystemslice = createSlice({
    "name": "filesystem",
    "initialState":{
        "folders":[],
        "writefolders": [],
        "foldercreated": false,
        "folderdeleted": false,
        "folderfiles":[],
        "createfileaction": false,
        "createfileres": null,
        "filedeleted": false,
        "folderworkspacepermission": "",
        "filefolderpermission": "",
        "totalfiles": 0
    },
    "reducers":{
        "resetfolders":(state, action)=>{
            state.folders = [];
        },
        "resetfoldercreated": (state, action)=>{
            state.foldercreated = false;
        },
        "resetfolderdeleted": (state, action)=>{
            state.folderdeleted = false;
        },
        "resetfolderfiles": (state, aciton)=>{
            state.folderfiles = [];
        },
        "resetfilecreated": (state, action)=>{
            state.createfileaction = false;
            state.createfileres = null;
        },
        "resetfiledeleted": (state, action)=>{
            state.filedeleted = false;
        }
    },
    "extraReducers":(builder)=>{
        builder.addCase(getFolders.fulfilled, (state, action)=>{
            state.folders = action.payload.folderlist;
            state.folderworkspacepermission = action.payload.access
        })
        builder.addCase(getWriteFolders.fulfilled, (state, action)=>{
            state.writefolders = action.payload.folderlist;
        })
        builder.addCase(createFolder.fulfilled, (state, action)=>{
            state.foldercreated = true;
        })
        builder.addCase(deleteFolder.fulfilled, (state, action)=>{
            state.folderdeleted = true;
        })
        builder.addCase(filesInFolder.fulfilled, (state, action)=>{
            state.folderfiles = action.payload.filelist;
            state.filefolderpermission = action.payload.access
        })
        builder.addCase(createFile.fulfilled, (state, action)=>{
            state.createfileres = action.payload;
            state.createfileaction = true;
        })
        builder.addCase(deleteFile.fulfilled, (state, action)=>{
            state.filedeleted = true;
        })
        builder.addCase(changePrivacy.fulfilled, (state, action)=>{

        })
        builder.addCase(filesInFolderCount.fulfilled, (state, action)=>{
            state.totalfiles = action.payload.count; 
        })
    }
})


export const selectFolders = (state) => state.filesystem.folders;
export const selectFolderCreated = (state) => state.filesystem.foldercreated;
export const selectFolderDeleted = (state) => state.filesystem.folderdeleted; 
export const selectFileCreatedRes = (state) => state.filesystem.createfileres;
export const selectFileCreatedAction = (state) => state.filesystem.createfileaction;
export const selectFileDeleted = (state) => state.filesystem.filedeleted;
export const selectFolderfiles = (state) => state.filesystem.folderfiles;
export const selectFolderWorkspacepermission = (state) => state.filesystem.folderworkspacepermission;
export const selectFileFolderpermission = (state) => state.filesystem.filefolderpermission;
export const selectWriteFolders = (state) => state.filesystem.writefolders; 
export const selectTotalfiles = (state) => state.filesystem.totalfiles;

export const {
                resetfolders, 
                resetfoldercreated, 
                resetfolderdeleted, 
                resetfolderfiles, 
                resetfilecreated, 
                resetfiledeleted
            } = filesystemslice.actions;

export default filesystemslice.reducer;

import { useState, useEffect, useRef } from "react";
import "./AppDesigner.css";
import Toiler from "../../Images/logo1.png";
import { Button, IconButton, LinearProgress, TextField, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useParams , Link} from "react-router-dom";
import Switch from '@mui/material/Switch';
import MainElementContainer from "./ElementNavContainer";
import Proptoolbar from "./PropsToolbar";
import CodeNavContainer from "./CodeNavContainer";
import { useDispatch, useSelector } from "react-redux";
import { initNavigator } from "../../Services/pipelines/server/server-apps/router";
import { 
        getElements, 
        getTemplateSettings, 
        selectActiveElements, 
        selectActiveTemplateSettings, 
        updateTemplate, 
        updateTemplateSettings,
        getTemplateVariables,
        templatePipleine,
        setTemplateId,
        resetactivetemplateid,
        resetelements,
        resetvariables,
        getRouteParams,
        setProd,
        setCurrentPath,
        setmode,
        resetmode,
        selectActivetemplateid,
        getTemplateChildEnabled,
        selectTemplateClasses,
        updateTemplateClassesRemote,
        resetcssdesigner,
        selectDesignerLoading,
        resetappinputs,
        resettemplatePipelines
    } from "../../Services/apptemplates/apptemplates.slice";
import { deployTemplate} from "../../Services/templates/template.slice";

import StylesToolbar from "./StylesToolbar";
import InteractionToolbar from "./InteractionToolbar";
import AppContainer from "./AppContainer";
import Preview from "./preview/Preview";
import CssToolbar from "./CssToolbar";
import StyleSheet from "./StyleSheet";
import { selectActiveCollaborators, getActiveCollaborators, deactivatecollaborator } from "../../Services/collaborators/collaborators.slice";
import "./Animations.css";


import { createPortal } from 'react-dom'
const IFrame = ({
    children,
    ...props
  }) => {
    const [contentRef, setContentRef] = useState(null)
    
    const mountNode = useRef(null);
    
    useEffect(()=>{
        if(contentRef?.contentWindow?.document?.body!=undefined){

            mountNode.current = contentRef?.contentWindow?.document?.body;
        }
    },[contentRef])
  
    return (
      <iframe {...props} ref={setContentRef}>
        {mountNode.current && createPortal(children, mountNode.current)}
      </iframe>
    )
  }


const AppDesigner = ()=>{

    const [canvasWidth, setCanvasWidth] = useState(1520);

    const {type,__source, __id, workspace} = useParams();

    const [showpreview, setShowPreview] = useState(false);

    const [leftoolbarstyle, setlefttoolbarstyle] = useState({
                                                                maxWidth: "700px"
                                                            })

    const [righttoolbarstyle, setrighttoolbarstyle] = useState({
                            maxWidth: "700px"
                        })

    const [righttoolbarvisible, setRighttoolbarvisible] = useState(false);

    const [lefttoolbarvisible, setLefttoolbarvisible] = useState(false);

    const [breakpoint, setBreakpoint] = useState(0);


    // manage canvas width according to the screen width
    useEffect(()=>{
        if(window.innerWidth>=1800){
            setCanvasWidth(1520);
        }else if(window.innerWidth<1800&&window.innerWidth>=1400){
            setCanvasWidth(1320)
        }else if(window.innerWidth<1400){
            setCanvasWidth(1120)
        }
    },[])

    const resizeRightToolbar = (event)=>{
        let screenwidth = window.innerWidth;
        let eventX = event.clientX;
        let size = screenwidth-eventX;
        if(eventX>0&&size>50){
            let righttoolbarstylecopy = {...righttoolbarstyle,
                                     minWidth: size
                                    }
            setrighttoolbarstyle(righttoolbarstylecopy);
        }        
    }

    const resizeLeftToolbar = (event)=>{
        let screenwidth = window.innerWidth;
        let eventX = event.clientX;
        let size = eventX-50;
        if(eventX>50){
            let leftoolbarstylecopy = {...leftoolbarstyle,
                                       minWidth: size
                                      }
            setlefttoolbarstyle(leftoolbarstylecopy)
        }
    }

    const closePreview = ()=>{
        setShowPreview(false);
    }

    const dispatch = useDispatch();

    const navigate = useNavigate();

    // template settings
    const [settings, setSettings] = useState({
        jsenabled: true
    });

    const setSettingsAction = (settings)=>{
        let settingsCopy = {...settings};
        dispatch(updateTemplateSettings({
            id: __id,
            settings: settingsCopy
        }));
        setSettings(settingsCopy)
    }

    const remotesettings = useSelector(selectActiveTemplateSettings);

    useEffect(()=>{
        if(remotesettings==undefined){
            return
        }
        let remotesettingsCopy = {...remotesettings};
        setSettings(remotesettingsCopy);
    },[remotesettings])

    useEffect(()=>{
        dispatch(getTemplateSettings(__id));
        dispatch(getRouteParams(__id));
    },[])

    const setJsenabled = (val)=>{
        let settingsCopy = {...settings,
                            jsenabled: val
                            };
        setSettingsAction(settingsCopy);
    }

    const close = ()=>{
        dispatch(resetactivetemplateid());
        dispatch(resetelements());
        dispatch(resetvariables());
        dispatch(resetcssdesigner());
        dispatch(resetappinputs());
        dispatch(resettemplatePipelines());
        if(__source=="templates"){
            navigate("/"+workspace+"/templates");
        }else{
            if(type=="html"){
                navigate("/"+workspace+"/website/details/"+__source);
            }else if(type=="android"){
                navigate("/"+workspace+"/apps/details/"+__source);
            }
        }
    }

    useEffect(()=>{
        return ()=>{
            dispatch(resetactivetemplateid());
            dispatch(resetelements());
            dispatch(resetvariables());
            dispatch(resetcssdesigner());
            dispatch(resetappinputs());
            dispatch(resettemplatePipelines());
        }
    },[])

    const [toolbaractive, setToolbaractive] = useState("props");

    const [drawerOpen, setDrawerOpen] = useState("");

    const elements = useSelector(selectActiveElements);

    useEffect(()=>{
        dispatch(setmode("designer"));
        return ()=>{
            dispatch(resetmode());
        }
    },[])
    
    useEffect(()=>{
        if(__id==undefined){
            return;
        }
        dispatch(setTemplateId(__id));
    },[__id])

    const [elementInFocus, setElementInFocus] = useState([]);

    const [elementActive, setElementActive] = useState([]);

    useEffect(()=>{
        if(elements.length>0){
            dispatch(updateTemplate({
                templateid: __id,
                elements: elements
            }))
        }   
    },[elements])

    useEffect(()=>{
        if(type!=undefined&&__source!=undefined&&__id!=undefined){
            dispatch(setCurrentPath("/"+workspace+"/appdesigner/"+type+"/"+__source+"/"+__id))
        }
    },[type, __source, __id])

    useEffect(()=>{
        if(__id!=undefined&&__source!=undefined){
            dispatch(templatePipleine({
                "source": __source,
                "templateid": __id
            }));
            dispatch(setProd(false));
        }
    },[__id, __source])

    const activetemplateid = useSelector(selectActivetemplateid);

    useEffect(()=>{
        if(activetemplateid!=""&&activetemplateid!=undefined){
            dispatch(
                getElements(
                            {
                                "templateid": __id,
                                "source": __source
                            }
                        )
            )
            dispatch(getTemplateVariables({
                "templateid": __id,
                "source": __source
            }));
            dispatch(getTemplateChildEnabled(__id));
        }
    },[activetemplateid])

    useEffect(()=>{
        if(type=="android"){
            setCanvasWidth(350)
        }
    },[type])

    useEffect(()=>{
        if(navigate!=undefined){
            let currentpath = "/"+workspace+"/appdesigner/"+type+"/"+__source+"/"+__id;
            initNavigator(navigate, currentpath);
        }
    },[])

    useEffect(()=>{
        dispatch(getActiveCollaborators({
            "resource": "template",
            "resourceid": __id
        }))
        return ()=>{
            dispatch(deactivatecollaborator({
                "resource": "template",
                "resourceid": __id
            }))
        }
    },[__id])


    const deploytemplate = (id)=>{
        dispatch(deployTemplate({
            id: id,
            source: __source,
            workspace: workspace
        }))
    }

    const activeusers = useSelector((state)=>{return selectActiveCollaborators(state, "template", __id)});

    const [showbackbutton , setShowBackButton] = useState(false);

    const location = useLocation();

    useEffect(()=>{
        if(
            location!=undefined&&
            location.state!=undefined&&
            location.state.fromdesigner==true
         ){
            if(showbackbutton!=true){
                setShowBackButton(true);
            }else{
                setShowBackButton(false);
            }
        }
    },[location])

    const navigateBack = ()=>{
        navigate(-1);
    }

    //update css to remote on change
    const templateclasses = useSelector(selectTemplateClasses);

    useEffect(()=>{
        if(templateclasses.length>0){
            dispatch(updateTemplateClassesRemote({
                templateid: __id,
                classes: templateclasses
            }))
        }
    },[templateclasses])

    const designerloading = useSelector(selectDesignerLoading);

    const changeBreakPoint = (val)=>{
        if(val==0){
            if(window.innerWidth>=1800){
                setCanvasWidth(1520);
            }else if(window.innerWidth<1800&&window.innerWidth>=1400){
                setCanvasWidth(1320)
            }else if(window.innerWidth<1400){
                setCanvasWidth(1120)
            }
            setBreakpoint(0);
        }else if(val==1){
            setCanvasWidth(400);
            setBreakpoint(1);
        }
    }

    return (
        <div className="app-designer-container">
            <div className="app-designer-header">
                <div>
                    <Link to="/">
                        <img src={Toiler}></img>
                    </Link>
                </div>
                <div className="app-canvas-width">
                    <input 
                        style={{width: 50}} 
                        type="number" 
                        value={canvasWidth} 
                        onChange={
                                    (e)=>{setCanvasWidth(parseInt(e.target.value))}
                                 }>
                    </input> 
                    <span style={{color: "#fff"}}>px</span>
                    <Tooltip title="Desktop">
                        <IconButton onClick={()=>{changeBreakPoint(0)}}>
                            <span className={breakpoint==0?"material-symbols-outlined bactive":"material-symbols-outlined"}>
                                desktop_windows
                            </span>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Mobile">
                        <IconButton onClick={()=>{changeBreakPoint(1)}}>
                            <span className={breakpoint==1?"material-symbols-outlined bactive":"material-symbols-outlined"}>
                                smartphone
                            </span>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="app-header-right-toolbar">
                    <div>
                        {activeusers!=undefined&&
                            <>
                                {
                                    activeusers.map((activeuser)=>{
                                        return (
                                            <Tooltip title={activeuser.emailid}>
                                                <IconButton>
                                                    <span style={{color: "#fff"}} className="material-symbols-outlined">
                                                        account_circle
                                                    </span>
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </>
                        }
                    </div>
                    <div style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={()=>{setShowPreview(true)}}>
                        <span style={{fontSize: "0.8em", color: "#fff"}}>Preview</span>
                        <span className="material-symbols-outlined preview">
                            play_arrow
                        </span>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span style={{color: "#fff" , fontSize: "0.8em"}}>Enable JS</span>
                        <Switch checked={settings.jsenabled} value={settings.jsenabled} onChange={()=>{setJsenabled(!settings.jsenabled)}} />
                    </div>
                    <div>
                        <Button 
                            onClick={()=>{deploytemplate(__id)}} 
                            style={{color: "#fff"}}
                        >Deploy</Button>
                    </div>
                    <div>
                        <Button onClick={()=>{close()}} style={{color:"#fff"}}>Close</Button>
                    </div>
                </div>
            </div>
            <div className="card left-toolbar">
                <div className="left-toolbar-drawer">
                    <Tooltip title="elements">
                        <IconButton onClick={()=>{setDrawerOpen("navigator")}}>
                            <span className={drawerOpen=="navigator"?"material-symbols-outlined active":"material-symbols-outlined"}>
                                iframe
                            </span>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Variables and Functions">
                        <IconButton onClick={()=>{setDrawerOpen("code")}}>
                            <span className={drawerOpen=="code"?"material-symbols-outlined active":"material-symbols-outlined"}>
                                code_blocks
                            </span>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="">
                        <IconButton onClick={()=>{setDrawerOpen("css")}}>
                            <span className={drawerOpen=="css"?"material-symbols-outlined active":"material-symbols-outlined"}>
                                css
                            </span>
                        </IconButton>
                    </Tooltip>
                </div>
                <div 
                    id="left-toolbar-1"
                    className={lefttoolbarvisible?"left-toolbar-collapse visible":"left-toolbar-collapse"} 
                    style={{
                                maxWidth: "700px",
                                display: "block",
                                background: "#fff",
                                overflow: "auto", 
                                opacity: "0.9"
                            }}
                >
                    {drawerOpen=="navigator"&&
                        <div style={leftoolbarstyle}>
                            <div className="navigator-header">
                                <div>
                                    <IconButton 
                                        disabled={!showbackbutton} 
                                        onClick={()=>{navigateBack()}}
                                    >
                                        <span className="material-symbols-outlined">
                                            arrow_back
                                        </span>
                                    </IconButton>
                                </div>
                                <div className="navigator-header-title">
                                    Navigator
                                </div>
                                <div>
                                    <IconButton onClick={()=>{setDrawerOpen("")}}>
                                        <span className="material-symbols-outlined">
                                            close
                                        </span>
                                    </IconButton>
                                </div>
                            </div>
                            <div>
                                <MainElementContainer 
                                    elements={elements}
                                    position={[]}
                                    setElementInFocus={setElementInFocus}
                                    setElementActive={setElementActive}
                                    elementInFocus={elementInFocus}
                                    elementActive={elementActive}
                                >
                                </MainElementContainer>
                            </div>
                        </div>
                    }
                    {drawerOpen=="code"&&
                        <div style={leftoolbarstyle}>
                            <div className="navigator-header">
                                <div className="navigator-header-title">
                                    Code
                                </div>
                                <IconButton onClick={()=>{setDrawerOpen("")}}>
                                    <span className="material-symbols-outlined">
                                        close
                                    </span>
                                </IconButton>
                            </div>
                            <div>
                                <CodeNavContainer
                                    jsEnabled={settings.jsenabled}
                                    templateid={__id}
                                    source={__source}
                                >
                                </CodeNavContainer>
                            </div>
                        </div>
                    }
                    {drawerOpen=="css"&&
                        <div style={leftoolbarstyle}>
                            <div className="navigator-header">
                                <div className="navigator-header-title">
                                    Css Classes
                                </div>
                                <IconButton onClick={()=>{setDrawerOpen("")}}>
                                    <span className="material-symbols-outlined">
                                        close
                                    </span>
                                </IconButton>
                            </div>
                            <div>
                                <CssToolbar
                                    templateid={__id}
                                >
                                </CssToolbar>
                            </div>
                        </div>
                    }
                </div>
                <div 
                    draggable
                    onDrag={(e)=>{
                        e.preventDefault()
                        resizeLeftToolbar(e)
                    }}
                    className="resize-right-tool-bar" onMouseEnter={()=>{setLefttoolbarvisible(true)}} onMouseLeave={()=>{setLefttoolbarvisible(false)}}
                >
                </div>
            </div>
            <div className="card right-toolbar">
                    <div 
                         draggable 
                         onDrag={(e)=>{
                            e.preventDefault();
                            resizeRightToolbar(e);
                         }}
                         className="resize-right-tool-bar" onMouseEnter={()=>{setRighttoolbarvisible(true)}} onMouseLeave={()=>{setRighttoolbarvisible(false)}}>
                    </div>
                    <div className={righttoolbarvisible?"right-toolbar-collapse visible":"right-toolbar-collapse"} style={{
                                                                                                                            maxWidth: "700px",
                                                                                                                            display: "block",
                                                                                                                            background: "#fff",
                                                                                                                            overflow: "auto",
                                                                                                                            opacity: "0.9"
                                                                                                                          }}>
                        {toolbaractive=="props"&&
                            <div style={righttoolbarstyle}>
                                <div className="navigator-header">
                                    <div className="navigator-header-title">
                                        Properties
                                    </div>
                                    <IconButton onClick={()=>{setToolbaractive("")}}>
                                        <span className="material-symbols-outlined">
                                            close
                                        </span>
                                    </IconButton>
                                </div>
                                <div>
                                    <Proptoolbar
                                        // element={getActiveElement()}
                                        templateid={__id}
                                        // setElement={setElementAction}
                                        position={elementActive}
                                    >
                                    </Proptoolbar>
                                </div>
                            </div>
                        }
                        {toolbaractive=="styles"&&
                            <div style={righttoolbarstyle}>
                                <div className="navigator-header">
                                    <div className="navigator-header-title">
                                        Styles
                                    </div>
                                    <IconButton onClick={()=>{setToolbaractive("")}}>
                                        <span className="material-symbols-outlined">
                                            close
                                        </span>
                                    </IconButton>
                                </div>
                                <div>
                                    <StylesToolbar
                                        // element={getActiveElement()}
                                        settings={settings}
                                        templateid={__id}
                                        position={elementActive}
                                    >
                                    </StylesToolbar>
                                </div>
                            </div>
                        }
                        {toolbaractive=="interactions"&&
                            <div style={righttoolbarstyle}>
                                <div className="navigator-header">
                                    <div className="navigator-header-title">
                                        Interactions
                                    </div>
                                    <IconButton onClick={()=>{setToolbaractive("")}}>
                                        <span className="material-symbols-outlined">
                                            close
                                        </span>
                                    </IconButton>
                                </div>
                                <div>
                                    <InteractionToolbar
                                        // element={getActiveElement()}
                                        templateid={__id}
                                        position={elementActive}
                                    >
                                    </InteractionToolbar>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="left-toolbar-drawer">
                        <Tooltip title="Element Properties">
                            <IconButton onClick={()=>{setToolbaractive("props")}}>
                                <span className={toolbaractive=="props"?"material-symbols-outlined active":"material-symbols-outlined"}>
                                    display_settings
                                </span>
                            </IconButton>
                        </Tooltip>
                        {settings.jsenabled&&
                            <Tooltip title="Element Interactions">
                                <IconButton onClick={()=>{setToolbaractive("interactions")}}>
                                    <span className={toolbaractive=="interactions"?"material-symbols-outlined active":"material-symbols-outlined"}>
                                        mouse
                                    </span>
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Element Styles">
                            <IconButton onClick={()=>{setToolbaractive("styles")}}>
                                <span className={toolbaractive=="styles"?"material-symbols-outlined active":"material-symbols-outlined"}>
                                    style
                                </span>
                            </IconButton>
                        </Tooltip>
                    </div>
            </div>
            <div className="app-main-canvas">
                {designerloading>0&&
                    <>
                        <LinearProgress></LinearProgress>
                    </>
                }
                <div className="app-designer-canvas">
                    <StyleSheet
                        canvasWidth={canvasWidth}
                        appdesginer={true}
                    ></StyleSheet>
                    {type=="html"&&
                        <>
                            <div
                                    className="app-canvas" 
                                    style={
                                            {
                                                width: canvasWidth, 
                                                height: "100%",
                                                overflow: "scroll"
                                            }
                                        }
                                >
                                {
                                    activetemplateid!=""&&
                                        <AppContainer
                                            templateid={__id}
                                            indexarr={[]}
                                            indexkey={__id}
                                            elmkey={""}
                                            type={type}
                                            path={""}
                                            indesign={true}
                                        >
                                        </AppContainer>
                                }
                            </div>
                            
                        </>
                    }
                    {type=="android"&&
                        <div className="app-canvas" style={{width: canvasWidth, height: "700px"}}>
                            {activetemplateid!=""&&
                                <AppContainer
                                    templateid={__id}
                                    indexarr={[]}
                                    elmkey={""}
                                    type={type}
                                >
                                </AppContainer>
                            }
                        </div>
                    }                    
                </div>
            </div>
            {showpreview&&
                <Preview
                    handleClose = {closePreview}
                >
    
                </Preview>
            }
        </div>
    )

}


export default AppDesigner;

import {ConditionBlock, FromBlock} from "./QueryBuilder";
import { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux";
import {getAppSchemas,selectAppSchemas} from "../../../Services/schemas/schema.slice";
import {getApps, selectApplist} from "../../../Services/apps/app.slice";
import * as _ from "lodash";
import { useParams } from "react-router-dom";


const DeleteBuilder = ({
    initapp,
    initschema,
    schema,
    schemamapping,
    setschemaMapping,
    modifySchema,
    setSchema,
    pipelinetype,
    pipelinestage,
    stageindex,
    stagetype,
})=>{

    const {workspace} = useParams();

    const dispatch = useDispatch();

    const applist = useSelector(selectApplist);

    const appschemas = useSelector(selectAppSchemas);
    
    const [availableFields, setAvailableFields] = useState([]);

    const [query, setQuery] = useState([
        {
            "type": "from", 
            "settings":{
                "table": {
                    "app": "",
                    "schemaslug": ""
                }
            },
            "enabled": true,
            "expanded": true
        },
        {
            "type": "where",
             "settings": {
                "conditions":[[]],
                "excludeundefined": false
             },
             "enabled": true,
             "expanded": true
        }
    ])
    

    const resetFromAvailableFields = (table)=>{
        let appindex = _.findIndex(appschemas,(as)=>{
                                                        return as.appname==table.app
                                                    });
        let app = {...appschemas[appindex]};
        let schemaindex = _.findIndex(app.schemalist, (sh)=>{ return sh.schemaslug==table.schemaslug});
        let schema = {...app.schemalist[schemaindex]};
        let avlFeilds = [];
        for(let i=0; i< schema.schema.length; i++){
            avlFeilds.push({
                "key": schema.schema[i].inputkey,
                "app": app.appname,
                "schema": schema.schemaslug,
                "type": schema.schema[i].inputtype,
                "source": "from" 
            })
        }
        // remove from the available fields
        for(let i=0; i< availableFields.length; i++){
            if(availableFields[i].source!="from"){
                avlFeilds.push(availableFields[i]);
            }
        }
        setAvailableFields(avlFeilds);
        
    }

    const setSettings=(index)=>{
        return (settings)=>{
            let queryCopy = [...query];
            if(queryCopy[index].type=="from"){
                let table = settings.table;
                resetFromAvailableFields(table)
            }
            queryCopy[index] = {...queryCopy[index],
                                settings: settings
                                };
            updateQuerySettingsMapping(queryCopy);
            setQuery(queryCopy);
        }
    }

    const updateQuerySettingsMapping = (query)=>{
            let schemamappingCopy = [...schemamapping];
            schemamappingCopy[2] = {...schemamappingCopy[2],
                                    mapping: {"action": "const", "val": query} 
                                   }
            setschemaMapping(schemamappingCopy);
    }

    const initializeAvailableFields = (query)=>{
        let queryCopy = [...query];
        let fromqueryindex = _.findIndex(queryCopy, (qc)=>{return qc.type=="from"});
        let fromapp = queryCopy[fromqueryindex].settings.table.app;
        let fromschemaslug = queryCopy[fromqueryindex].settings.table.schemaslug;
        let avlFeilds = [];
        let fromappindex = _.findIndex(appschemas,(as)=>{   
            return as.appname==fromapp
          });
        let fromschemaapp = {...appschemas[fromappindex]};
        let fromschemaindex = _.findIndex(fromschemaapp.schemalist, (sh)=>{ return sh.schemaslug==fromschemaslug});
        let fromschema = {...fromschemaapp.schemalist[fromschemaindex]};
        for(let i=0; i< fromschema.schema.length; i++){
            avlFeilds.push({
                "key": fromschema.schema[i].inputkey,
                "type": fromschema.schema[i].inputtype,
                "app": fromschemaapp.appname,
                "schema": fromschema.schemaslug,
                "source":  "from"
            })
        }
        setAvailableFields(avlFeilds);
    }

    useEffect(()=>{
        if(initapp==""||initschema?.schemaslug==""||initschema?.schemaslug==undefined||appschemas?.length==0){
            return;
        }
        let queryCopy = [...query];
        let fromqueryindex = _.findIndex(queryCopy, (qc)=>{return qc.type=="from"});
        if(queryCopy[fromqueryindex].settings.table.app==""&&queryCopy[fromqueryindex].settings.table.schemaslug==""){
            if(queryCopy[fromqueryindex].settings.table.app==""&&queryCopy[fromqueryindex].settings.table.schemaslug==""){
                queryCopy[fromqueryindex].settings = {...queryCopy[fromqueryindex].settings,
                    table: {
                        "app" : initapp,
                        "schemaslug": initschema.schemaslug
                    }   
                }
                resetFromAvailableFields({
                                            "app" : initapp,
                                            "schemaslug": initschema.schemaslug
                                        })
            }else{
                initializeAvailableFields(queryCopy);
            }
            setQuery(queryCopy);
        }
    },[initapp, appschemas])

    useEffect(()=>{
        for(let i=0; i< applist.length; i++){
            dispatch(getAppSchemas(applist[i]._id));
        }
    },[applist])


    useEffect(()=>{
        dispatch(getApps(workspace))
    },[])

    return(
        <div>
            <FromBlock
                settings={query[0].settings} 
                availableSchemas={appschemas} 
                setSettings={setSettings(0)}
            ></FromBlock>
            <ConditionBlock
                availableFields={availableFields} 
                setschemaMapping={setschemaMapping}
                modifySchema={modifySchema}
                pipelinetype={pipelinetype}
                pipelinestage={pipelinestage}
                stageindex={stageindex}
                schema={schema}
                schemamapping={schemamapping}
                stagetype={stagetype}
                setSchema={setSchema}
                settings={query[1].settings}
                setSettings={setSettings(1)}
                index={1}
                query={query}
                setQuery={setQuery}
                component="delete"
            >
            </ConditionBlock>
        </div>
    )
}

export default DeleteBuilder;
import { 
    availableApps,
    availableClientLoops,
    availableClientApps, 
    availableSchemaCommonActions,
    available_transfrom_action,
    availableLoops,
    availableResponses,
    QUERYSCHEMA,
    QUERYSCHEMAMAPPING,
    HTTPRESPONSEACTION,
    SUBPIPELINERESPONSE,
    availableTypes,
    availableSchemaCommonReadActions
} from "../../Settings";
import {getApps, selectApplist} from "../../../Services/apps/app.slice";
import {getAppSchemas, selectAppSchema} from "../../../Services/schemas/schema.slice";
import {
    selectTestActionOutput,
    selectTestactionError,
    selectPipelineTriggers,
    getPipelineTriggersBySchema,
    createNextStage
} from "../../../Services/pipelines/pipeline.slice";
import {getSchemaEvents, getLoops, selectAvailableLoops, selectSchemaevents} from "../../../Services/events/event.slice";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button, Select } from "@mui/material";
import { useEffect, useState } from "react";
import ChooseLoop from "../../apps/ChooseApp/ChooseLoops";
import DeclareVariable from "../../apps/Fields/DeclareVariable";
import "./SingleBlock.css";
import { useDispatch, useSelector } from "react-redux";
import QueryBuilder from "../../apps/QueryBuilder/QueryBuilder";
import DeleteBuilder from "../../apps/QueryBuilder/DeleteBuilder";
import * as _ from "lodash";
import UpdateBuilder from "../../apps/QueryBuilder/UpdateBuilder";
import {getsubschemamapping} from "../../apps/Fields/Utils";
import {
            testAction,
            selectStage,
            setStage,
            getVariablePipeline,
        } from "../../../Services/pipelines/pipeline.slice";

import {
            selectInterfaceTemplates, 
            selectInterfaceschema, 
            getInterfaceTemplates, 
            getTemplateSchema
        } from "../../../Services/templates/template.slice";

import {
            selectActiveTemplatePipelines, 
            selectAppPipelines
        } from "../../../Services/apptemplates/apptemplates.slice";

import { fileURL } from "../../../env";
import { 
            getOutput, 
            getOutputSchema, 
            getStageStatus, 
            getSyncStatus, 
            setStageStatus,
            getStageError,
            resetschema,
            resetschemapping
        } from "../../utils";
import CircularLoader from "../../utils/CircularLoader";
import DisplayObject from "../../apps/DisplayOutputs/DisplayObject";
import BlockOutput from "./BlockOutput";
import {getKey} from "../../appdesigner/utils";

import { useNavigate, useParams } from "react-router-dom";

const testEqual = (val1, val2)=>{
    if(JSON.stringify(val1)==JSON.stringify(val2)){
        return true
    }else{
        return false;
    }
}


const SingleBlock = ({
                        index,
                        pipelinetype,
                        pipelinestage,
                        stage,
                        isLog,
                        source,
                        templateid,
                        pipelineid
                    })=>{
        
    const {workspace} = useParams();

    const [availableActions, setAvailableActions] = useState([]);

    const  [apps, setApps] = useState([]);
    
    const [systemapps, setSystemapps] = useState([]);

    const [displaySchemaList, setDisplaySchemalist] = useState([]);

    const [displayEventList, setDisplayEventList] = useState([]);

    const [selectedSchema, setSelectedSchema] = useState(undefined);

    const [selectedApp, setSelectedApp] = useState([]);

    const [stageapptype, setStageapptype] = useState("system");

    const [inputSchemaVerfied, setInputSchemaVerfied] = useState(false);

    const [stageComplete, setStageComplete] = useState(false);
    
    const variablePipeline = useSelector((state)=>getVariablePipeline(state,pipelinestage),testEqual);

    const eventList = useSelector(state=>selectSchemaevents(state, selectedSchema));

    const [eventlistfetched, seteventlistfetched] = useState(false);

    const [schemafetched, setSchemafetched] = useState(false);
    
    const [actionloading, setactionloading] = useState(false);

    // state to hold trigerrs for scheduling
    const [schTriggers, setSchtriggers] = useState([]);

    const [templatepipelinesactions, setTemplatepipelinesActions] = useState([]);

    const dispatch = useDispatch();

    const setDisplayoutput = (val)=>{
        if(val){
            let stageCopy = {...stage,
                             status: true
                            }
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}))
        }else{
            let stageCopy = {...stage,
                status: undefined
               }
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }
    }

    // transform types
    const selectTypeAction = (type)=>{
        // let pipelineCopy = [...pipeline];
        let stageCopy = {...stage,
                            transformtype: type
                        }
        let alltransfromactions = [...available_transfrom_action];
        let selectedTypeIndex = _.findIndex(alltransfromactions, (al)=>{return al.type==type});
        let avlactions = [...alltransfromactions[selectedTypeIndex].actions]
        setAvailableActions(avlactions);
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }

    const setInputSchemaMapping = (schemamapping)=>{
        let stageCopy = {
                            ...stage,
                            inputschemamapping: schemamapping
                        };
        
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }

    const setInputSchemaStage = (schema)=>{
        let stageCopy = {...stage,
                         inputschema: schema
                        }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }

    const modifyInputSchema = (schema, schemamapping)=>{
        let stageCopy = { 
                            ...stage,
                            inputschema: schema,
                            inputschemamapping: schemamapping
                        }
        dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
    }

    const changeApp = ()=>{
        if(isLog==undefined){
            let stageCopy = {
                "type": "single",
                "app": "",
                "action":"",
                "outputschema":[],
                "outputschemamapping": [],
                "inputschema": [],
                "inputschemamapping":[],
                "subactions":[],
                "last": false,
                "schema":"",
                "event": ""
            }
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }
    } 
    
    const createnextstage = ()=>{
        dispatch(createNextStage({position: pipelinestage.position}))
    }

    const getInputSchemaAndMapping = (action,schema)=>{
        if(action=="CREATE"){
            let tableSchema = [...schema];
            let interfaceSchema = [];
            for(let i=0; i<tableSchema.length; i++){
                if(tableSchema[i].type!="system"){
                    interfaceSchema.push({
                        key: tableSchema[i].inputkey,
                        label: tableSchema[i].inputlabel,
                        type: tableSchema[i].inputtype,
                        required: tableSchema[i].required,
                        fillallowed: true,
                        help: tableSchema[i].inputhelp,
                        guided: false,
                        subschema: []
                    })
                }else{
                    interfaceSchema.push({
                        key: tableSchema[i].inputkey,
                        label: tableSchema[i].inputlabel,
                        type: tableSchema[i].inputtype,
                        required: tableSchema[i].required,
                        fillallowed: true,
                        help: tableSchema[i].inputhelp,
                        guided: false,
                        subschema: [],
                        inputtype: "system"
                    })
                }
            }
            let mapping = [];
            getsubschemamapping(interfaceSchema,mapping);
            return {
                "schema": interfaceSchema,
                "mapping": mapping
            }
        }
    }

    const templatepipelines = useSelector((state)=>{return selectAppPipelines(
                                                                                state, 
                                                                                getKey(templateid, []),
                                                                                {}
                                                                            )});

    
    const selectApp = (app, type, appid, appworkspace)=>{
        if(isLog==undefined){
            let stageCopy = {...stage,
                             app: app,
                             appid: appid,
                             apptype: type
                            };
            setSelectedApp(appid);
            if(type=="system"){
                let systemAppsCopy = [...apps];
                let appobject = systemAppsCopy.filter((av)=>{return av.app==appid})
                let avlactions = appobject[0].actions;
                setAvailableActions(avlactions);
            }else if(type=="event"){
                stageCopy = {...stageCopy,
                             appid: appid,
                             schemas:[]
                            }
                setSchemafetched(true);
                dispatch(getAppSchemas(appid));
            }
            else if(type=="automata"){
                stageCopy = {...stageCopy,
                             appid: appid,  
                             workspace: appworkspace,
                             schemas: []
                            }
                dispatch(getAppSchemas(appid));
            }else if(type=="transform"){
                stageCopy = {...stageCopy,
                             "transformtype": ""
                            }
            }else if(type=="interface"){
                dispatch(getInterfaceTemplates(appworkspace));
            }else if(type=="template"){
                let templatepipelinescopy = [...templatepipelines];
                let pipelineactions = [];
                for(let i=0; i< templatepipelinescopy.length; i++){
                    if(templatepipelinescopy[i].pipeline.length>0){
                        pipelineactions.push({
                            "id": templatepipelinescopy[i]._id,
                            "name": templatepipelinescopy[i].name,
                            "inputschema": resetschema(JSON.parse(JSON.stringify(templatepipelinescopy[i].pipeline[0].inputschema))),
                            "inputschemamapping": resetschemapping(JSON.parse(JSON.stringify(templatepipelinescopy[i].pipeline[0].inputschemamapping)))
                        })
                    }
                    
                }
                setAvailableActions(pipelineactions);
            }

            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }
    }

    const selectSchemaAction = (schema)=>{
        if(isLog==undefined){
            let stageCopy = {...stage,
                schema: schema,
                action: "",
                event: "",
                inputschema:[],
                inputschemamapping:[]    
               }
            let displaySchemaListCopy = [...displaySchemaList];
            let selectedschemaindex = _.findIndex(displaySchemaListCopy,(sc)=>{return sc.schemaslug==schema.schemaslug});
            let selectedSchemaCopy = {...displaySchemaListCopy[selectedschemaindex]};
            setSelectedSchema(selectedSchemaCopy);
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
            if(index==0&&pipelinetype=="main"){
                dispatch(getSchemaEvents(schema._id));
            }else{
                if(schema.shared){
                    if(schema.access=="write"){
                        setAvailableActions([...availableSchemaCommonActions]);
                        // load manual pipelines
                        dispatch(getPipelineTriggersBySchema(schema._id));
                    }else{
                        setAvailableActions([...availableSchemaCommonReadActions]);
                        // load manual pipelines
                        dispatch(getPipelineTriggersBySchema(schema._id));
                    }
                }else{
                    setAvailableActions([...availableSchemaCommonActions]);
                    // load manual pipelines
                    dispatch(getPipelineTriggersBySchema(schema._id));
                }
            }
        }
    }

    const selectAppAction = (action)=>{
        if(isLog==undefined){
            let selectedAction = availableActions.filter((ac)=>{return ac.id==action});
            let stageCopy = {...stage,
                             action: action,
                             inputschema: [...selectedAction[0].inputschema],
                             inputschemamapping: [...selectedAction[0].inputschemamapping],
                             outputschema: []
                            };
            let ifResponse = _.findIndex(availableResponses, (ar)=>{return ar==action});
            if(ifResponse>-1){
                stageCopy = {...stageCopy,
                             last: true
                            }
            }        
            if(stage.apptype=="automata"){
                let sSchema = selectedSchema.schema;
                if(action=="CREATE"){
                    let inputschemaandmapping = getInputSchemaAndMapping(action,sSchema);
                    stageCopy = {...stage,
                                action: action,
                                inputschema: [...inputschemaandmapping.schema],
                                inputschemamapping: [...inputschemaandmapping.mapping],
                                outputschema: [],
                                };
                }else if(action=="QUERY"){
                    stageCopy = {...stage,
                                action: action,
                                inputschema: [...QUERYSCHEMA],
                                inputschemamapping: [...QUERYSCHEMAMAPPING],
                                outputschema:[]
                                }
                }else if(action=="UPDATE"){
                    stageCopy = {...stage,
                                 action: action,
                                 inputschema:[...QUERYSCHEMA],
                                 inputschemamapping:[...QUERYSCHEMAMAPPING],
                                 outputschema:[]
                                }
                }else if(action=="DELETE"){
                    stageCopy = {...stage,
                                 action: action,
                                 inputschema:[...QUERYSCHEMA],
                                 inputschemamapping:[...QUERYSCHEMAMAPPING],
                                 outputschema:[]
                                }
                }else if(action=="REGISTER_EVENT"){
                    stageCopy = {...stage,
                                event: "",
                                action: action
                                }
                    dispatch(getSchemaEvents(selectedSchema._id));
                }else if(action=="SCHEDULE"){
                    // stageCopy = {...stage,
                    //              pipeline: ""
                    //             }
                }
            }

            if(stage.apptype=="template"){
                if(action=="setinterval"){
                    let templatepipelinescopy = [...templatepipelines];
                    let pipelineactions = [];
                    for(let i=0; i< templatepipelinescopy.length; i++){
                        if(templatepipelinescopy[i].pipeline.length>0){
                            pipelineactions.push({
                                "id": templatepipelinescopy[i]._id,
                                "name": templatepipelinescopy[i].name,
                                "inputschema": resetschema(JSON.parse(JSON.stringify(templatepipelinescopy[i].pipeline[0].inputschema))),
                                "inputschemamapping": resetschemapping(JSON.parse(JSON.stringify(templatepipelinescopy[i].pipeline[0].inputschemamapping)))
                            })
                        }
                        
                    }
                    setAvailableActions(pipelineactions);
                }else if(action=="settimeout"){

                }
            }
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }
    }

    const selectAutomataEvent = (eventid)=>{
        if(isLog==undefined){
            let eventListCopy = [...displayEventList];
            let event = eventListCopy.filter(ev => ev.id==eventid);
            // let inputschemamapping = [];
            // getsubschemamapping(event[0].inputschema, inputschemamapping)
            let stageCopy = {...stage,
                event: eventid,
                inputschema:[...event[0].inputschema],
                inputschemamapping: [...event[0].inputschemamapping],
                outputschema:[]
            }
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }    
    }

    const selectSchTrigger = (action)=>{
        if(isLog==undefined){
            let selectedAction = schTriggers.filter((ac)=>{return ac.id==action});
            let stageCopy = {...stage};
            let inputschema = [...stageCopy.inputschema];
            let inputschemamapping = [...stageCopy.inputschemamapping];
            let pipelineSchema = resetschema(JSON.parse(JSON.stringify(selectedAction[0].inputschema)));
            let pipelineSchemaMapping = [...selectedAction[0].inputschemamapping];
            inputschema[2] = {...inputschema[2],
                            subschema: pipelineSchema
                            }
            inputschemamapping[2] = {...inputschemamapping[2],
                                    mapping: pipelineSchemaMapping
                                    }
            stageCopy = {...stage,
                pipeline: action,
                inputschema: inputschema,
                inputschemamapping: inputschemamapping,
                outputschema: []
            };
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}))
        }
    }

    // called on the component mount
    useEffect(()=>{
        if(source=="apps"){
            if(index==0&&pipelinetype=="main"){
                setApps([...availableLoops])
            }else{
                setApps([...availableApps]);
            }
        }
        if(source=="installmanifest"){
            if(index==0&&pipelinetype=="main"){
                setApps([...availableClientLoops]);
            }else{
                setApps([...availableApps]);
            }
        }
        if(source=="apptemplates"){
            if(index==0&&pipelinetype=="main"){
                setApps([...availableClientLoops])
            }else{
                setApps([...availableClientApps])
            }
        }
    },[index])

    // load applist on initial load
    useEffect(()=>{
        if(source=="apps"){
            if(variablePipeline.length>0){
                let systemapps = [...availableApps];
                if(pipelinetype=="main"){
                    let looppipeline = variablePipeline;
                    if(looppipeline[0].appid=="manual_trigger"){
                        let manaulTriggerIndex = _.findIndex(availableLoops, (loop)=>{return loop.app=="manual_trigger"});
                        let manualTriggerApp = {...availableLoops[manaulTriggerIndex]};
                        manualTriggerApp = {...manualTriggerApp,
                                            actions: [{...SUBPIPELINERESPONSE}]    
                                           }
                        systemapps = [manualTriggerApp, ...systemapps];
                    }else if(looppipeline[0].appid=="http_server"){
                        let httpServerIndex = _.findIndex(availableLoops, (loop)=>{return loop.app=="http_server"});
                        let httpServerApp = {...availableLoops[httpServerIndex]};
                        httpServerApp = {...httpServerApp,
                                         actions:[...HTTPRESPONSEACTION]
                                        }
                        systemapps = [httpServerApp, ...systemapps];
                    }
                }else{
                    // get the main pipeline
                    let looppipeline = variablePipeline;
                    if(looppipeline[0].appid=="manual_trigger"){
                        let manualTriggerIndex = _.findIndex(availableLoops, (loop)=>{return loop.app=="manual_trigger"});
                        let manualTriggerApp = {...availableLoops[manualTriggerIndex]};
                        manualTriggerApp = {...manualTriggerApp,
                                            actions: [{...SUBPIPELINERESPONSE}]    
                                           }
                        systemapps = [manualTriggerApp, ...systemapps];
                    }else if(looppipeline[0].appid=="http_server"){
                        let httpServerIndex = _.findIndex(availableLoops, (loop)=>{return loop.app=="http_server"});
                        let httpServerApp = {...availableLoops[httpServerIndex]};
                        httpServerApp = {...httpServerApp,
                                         actions:[...HTTPRESPONSEACTION]
                                        }
                        systemapps = [httpServerApp, ...systemapps];
                    }
                }
                let finalapps = [...systemapps];
                if(pipelinetype=="main"){
                    if(index>0){
                        setApps(finalapps);
                    }
                }else{
                    setApps(finalapps);
                }
            }
        }
    },[variablePipeline])

    // load stage variables on initial load of applications.
    useEffect(()=>{
        if(stage!=undefined&&stage!=null&&stage!={}&&apps!=undefined&&apps.length>0){
            setSelectedApp(stage.appid);
            if(stage.apptype=="system"){
                let appsCopy = [...apps];
                let appobject = appsCopy.filter((av)=>{return av.app==stage.appid})
                let avlactions = appobject[0].actions;
                setStageapptype("system");
                setAvailableActions(avlactions);
            }else if(stage.apptype=="automata"){
                if(schemafetched==false){
                    dispatch(getAppSchemas(stage.appid));
                    setAvailableActions([...availableSchemaCommonActions]);
                    setSelectedSchema(stage.schema);
                    setSchemafetched(true);
                    if(stage.action=="REGISTER_EVENT"){
                        if(eventlistfetched==false){
                            dispatch(getSchemaEvents(stage.schema._id));
                            seteventlistfetched(true);
                        }
                    }
                }
            }else if(stage.apptype=="transform"){
                let alltransfromactions = [...available_transfrom_action];
                let selectedTypeIndex = _.findIndex(alltransfromactions, (al)=>{return al.type==stage.transformtype});
                if(selectedTypeIndex>-1){
                    let avlactions = [...alltransfromactions[selectedTypeIndex].actions]
                    setAvailableActions(avlactions);
                }
            }else if(stage.apptype=="event"){
                setSelectedSchema(stage.schema);
                if(schemafetched==false){
                    dispatch(getAppSchemas(stage.appid));
                    setSchemafetched(true);
                }
                if(eventlistfetched==false){
                    if(stage.schema!=""){
                        dispatch(getSchemaEvents(stage.schema._id));
                        seteventlistfetched(true);
                    }
                }
            }else if(stage.apptype=="interface"){
                dispatch(getInterfaceTemplates(workspace));
            }
        }else if(stage.apptype=="template"){
            let templatepipelinescopy = [...templatepipelines];
            let pipelineactions = [];
            for(let i=0; i< templatepipelinescopy.length; i++){
                if(templatepipelinescopy[i].pipeline.length>0){
                    pipelineactions.push({
                                            "id": templatepipelinescopy[i]._id,
                                            "name": templatepipelinescopy[i].name,
                                            "inputschema": resetschema(JSON.parse(JSON.stringify(templatepipelinescopy[i].pipeline[0].inputschema))),
                                            "inputschemamapping": resetschemapping(JSON.parse(JSON.stringify(templatepipelinescopy[i].pipeline[0].inputschemamapping)))
                                        })
                }
            }

            setTemplatepipelinesActions(pipelineactions);
        }
    },[stage, apps]);

    const schemalist = useSelector(state=>selectAppSchema(state, selectedApp));
    
    useEffect(()=>{
        if(schemalist.length>0){
            setDisplaySchemalist(schemalist);
        }
    },[schemalist])

    // schema verfication of input parameters
    useEffect(()=>{
        if(inputSchemaVerfied==false&&stage.inputschema!=undefined&&_.isEmpty(stage.inputschema)!=true){
            let alldone = true;
            let inputschemacopy = [...stage.inputschema];
            for(let i = 0; i < inputschemacopy.length; i++){
                if(inputschemacopy[i].required){
                    let schemamappingindex = _.findIndex(stage.inputschemamapping, (sc)=>{return (sc.key==inputschemacopy[i].key)});
                    if(stage.inputschemamapping[schemamappingindex]!=""){
                        if(stage.inputschemamapping[schemamappingindex]?.mapping?.val==""||stage.inputschemamapping[schemamappingindex]?.mapping?.val==undefined){
                            alldone = false;
                        }
                    }else{
                        alldone = false;
                    }
                }
            }
            if(alldone){
                setInputSchemaVerfied(true);
            }
        }
    },[stage])

    useEffect(()=>{
        if(eventList.length>0){
            let eventListCopy = [...eventList];
            let eventActions = [];
            for(let i=0; i < eventListCopy.length; i++){
                let inputschemamapping = [];
                getsubschemamapping(eventList[i].schema, inputschemamapping);
                eventActions.push({
                    "name": eventList[i].name,
                    "id": eventList[i]._id,
                    "inputschema": eventList[i].schema,
                    "inputschemamapping": [...inputschemamapping]
                })
            }
            if(index==0&&pipelinetype=="main"){
                if(stage.apptype=="event"){
                    setAvailableActions(eventActions);
                }
            }else{
                setDisplayEventList(eventActions);
            }
        }
    },[eventList])

    //fetch schematriggers on schemaselected
    useEffect(()=>{
        if(selectedSchema?._id==undefined){
            return
        }
        dispatch(getPipelineTriggersBySchema(selectedSchema._id));
    },[selectedSchema]);

    const pipelineTriggers = useSelector(selectPipelineTriggers);

    useEffect(()=>{
        if(stage.schema?._id==pipelineTriggers.schemaid&&pipelineTriggers!=undefined){
            if(pipelineTriggers?.triggers?.length>0){
                if((index==0&&pipelinetype=="main")==false){
                    let pipelineTriggersCopy = [...pipelineTriggers.triggers];
                    let availableActionCopy = [...availableActions];
                    for(let i=0; i< pipelineTriggersCopy.length; i++){
                        let exists = _.findIndex(availableActionCopy, (act)=>{ return act.id==pipelineTriggersCopy[i].id});
                        if(exists<0){
                            availableActionCopy.push(pipelineTriggersCopy[i]);
                        }
                    }
                    setAvailableActions(availableActionCopy);
                    setSchtriggers(pipelineTriggersCopy);
                }  
            }
        }
    },[pipelineTriggers])

    //navigate to be passed in templates
    const navigate = useNavigate();

    // todo: input schema verifcation
    const testaction = ()=>{
        setactionloading(true);
        if(inputSchemaVerfied){
            if(source=="apps"){
                dispatch(testAction(
                    {
                        stageindex:pipelinestage.stageindex,
                        source: source
                    }));
            }else{
                dispatch(testAction(
                    {
                        stageindex:pipelinestage.stageindex,
                        source: source,
                        templateid: templateid,
                        navigate: navigate
                    }));
            }
                
        }else{
            if(source=="apps"){
                dispatch(testAction(
                    {
                        stageindex:pipelinestage.stageindex,
                        source: source
                    }));
            }else{
                dispatch(testAction(
                    {
                        stageindex:pipelinestage.stageindex,
                        source: source,
                        templateid: templateid,
                        navigate: navigate
                    }));
            }    
        }
    }


    const selectFunctionAction = (val)=>{
        let pipelineindex = _.findIndex(templatepipelinesactions, (action)=>{return action.id==val});
        if(pipelineindex>-1){
            let stageCopy = {
                                ...stage,
                                pipelineid: val,
                                inputschema: resetschema(JSON.parse(JSON.stringify(templatepipelinesactions[pipelineindex].inputschema))),
                                inputschemamapping: [...templatepipelinesactions[pipelineindex].inputschemamapping]
                            };
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }
    }

    //undo loading
    useEffect(()=>{
        if(actionloading){
            setactionloading(false);
        }
    },[stage])

    const getIsLast = ()=>{
        return stage.last;
    }

    //interfaces
    const interfaceTemplates = useSelector(selectInterfaceTemplates);

    const interfaceschema = useSelector(selectInterfaceschema);

    const selectTemplate = (val)=>{
        if(isLog==undefined){
            let stageCopy = {...stage,
                              template: val,
                              inputschema:[],
                              inputschemamapping: []
                            }
            dispatch(getTemplateSchema(val));
            dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
        }
    }

    useEffect(()=>{
        if(stage.apptype=="interface"&&stage.template!=undefined){
            if(interfaceschema.template==stage.template){
                let stageCopy = {...stage,
                                 inputschema: [...interfaceschema.schema],
                                 inputschemamapping: [...interfaceschema.schemamapping]
                                }
                dispatch(setStage({position: pipelinestage.position, stage: stageCopy}));
            }
        }
    },[interfaceschema])
        
    return(
        <>
            {stage!=null&&
                <div className="stage-type-single">
                    {stage.app==""?
                        <ChooseLoop 
                            selectApp={selectApp} 
                            systemapps={apps} 
                            index={index} 
                            pipelinetype={pipelinetype}
                            source={source}
                        ></ChooseLoop>:
                        <div className="stage-app-container">
                            <div className="stage-app-name-container">
                                {stage.app}
                            </div>
                            <div className="stage-app-change-container">
                                <Button onClick={()=>{changeApp()}}
                                    style={{color: "#3A07CD"}}
                                >Change App</Button>
                            </div>
                        </div>
                    }
                    {stage.apptype=="event"&&
                        <>
                            {stage.app!==""&&
                                <div className="stage-action-container">
                                    <div>
                                        Schema
                                    </div>
                                    {displaySchemaList.length>0&&
                                        <div>
                                            <FormControl>
                                                <Select 
                                                        className="toiler-select-input"
                                                        value={stage.schema} 
                                                        onChange={(e)=>{selectSchemaAction(e.target.value)}}
                                                        renderValue={(v)=>{return v.schemaname}}
                                                >
                                                    {displaySchemaList.map((action)=>{
                                                        return (
                                                            <MenuItem value={action}>{action.schemaname}</MenuItem>
                                                        )   
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                            }
                            {(stage.schema!=""&&stage.schema!="choose_schema"&&stage.schema!=undefined)&&
                                <>
                                    <div className="stage-action-container">
                                        <div>
                                            Event
                                        </div>
                                        {availableActions.length>0&&
                                            <div>
                                                <FormControl>
                                                    <Select
                                                        className="toiler-select-input" 
                                                        value={stage.action} 
                                                        onChange={(e)=>{selectAppAction(e.target.value)}}
                                                    >
                                                        {availableActions.map((action)=>{
                                                            return (
                                                                <MenuItem value={action.id}>{action.name}</MenuItem>
                                                            )   
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                    {(stage.action!=""&&stage.action!=undefined)&&
                                        <>
                                        <div className="stage-params">
                                            <div className="stage-params-header">
                                                Input Parameters
                                            </div>
                                            <div className="declare-variable">
                                                <DeclareVariable
                                                    schema={stage.inputschema}
                                                    schemamapping={stage.inputschemamapping}
                                                    setschemaMapping={setInputSchemaMapping}
                                                    modifySchema = {modifyInputSchema}
                                                    setSchema={setInputSchemaStage}
                                                    pipelinetype={pipelinetype}
                                                    stageindex={stage.stageindex}
                                                    stagetype={stage.type}
                                                    layout="vertical"
                                                    editable={true}
                                                    pipelinestage = {pipelinestage}
                                                    source={source}
                                                    templateid={templateid}
                                                    sourceid={pipelineid}
                                                >
                                                </DeclareVariable>
                                            </div>
                                            {stage.status!=undefined&&stage.status!=false?
                                                <div className="continue-input">
                                                    {isLog==undefined&&
                                                        <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                    }
                                                </div>:
                                                <div className="continue-input">
                                                    {isLog==undefined&&
                                                        <>
                                                            {actionloading?
                                                                <CircularLoader>
                                                                </CircularLoader>:
                                                                <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                            }
                                                        </>
                                                        
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {stage.status!=undefined&&stage.status!=false&&
                                            <div className="stage-params">
                                                <BlockOutput
                                                    stageindex={stage.stageindex}
                                                    pipelineid={pipelineid}
                                                >
                                                </BlockOutput>
                                                {stageComplete?
                                                    <>
                                                    </>:
                                                    <div className="stage-continue">
                                                        {isLog==undefined&&
                                                            <Button 
                                                            onClick={()=>{
                                                                setStageComplete(true);
                                                                createnextstage();
                                                            }}
                                                            style={{color: "#3A07CD"}}
                                                            >
                                                                Continue
                                                            </Button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                    {stage.apptype=="interface"&&
                        <>
                            {stage.app!==""&&
                                <div className="stage-action-container">
                                     <div>
                                        Template
                                    </div>
                                        <div>
                                            <FormControl>
                                                <Select 
                                                        className="toiler-select-input"
                                                        value={stage.template} 
                                                        onChange={(e)=>{selectTemplate(e.target.value)}}
                                                >
                                                    {interfaceTemplates?.map((action)=>{
                                                        return (
                                                            <MenuItem value={action._id}>{action.name}</MenuItem>
                                                        )   
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                </div>
                            }
                            {stage.template!=undefined&&stage.template!=""&&
                                <>
                                    <div className="stage-params">
                                        <div className="stage-params-header">
                                            Input Parameters
                                        </div>
                                        <div className="declare-variable">
                                            <DeclareVariable
                                                schema={stage.inputschema}
                                                schemamapping={stage.inputschemamapping}
                                                setschemaMapping={setInputSchemaMapping}
                                                modifySchema = {modifyInputSchema}
                                                setSchema={setInputSchemaStage}
                                                declaration={"sequential"}
                                                pipelinetype={pipelinetype}
                                                stageindex={stage.stageindex}
                                                stagetype={stage.type}
                                                layout="vertical"
                                                editable={true}
                                                pipelinestage = {pipelinestage}
                                                source={source}
                                                templateid={templateid}
                                                sourceid={pipelineid}
                                            >
                                            </DeclareVariable>
                                        </div>
                                        {getIsLast()==false?
                                            <>
                                                {stage.status!=undefined&&stage.status!=false?
                                                    <div className="continue-input">
                                                        {isLog==undefined&&
                                                            <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                        }
                                                    </div>:
                                                    <div className="continue-input">
                                                        {isLog==undefined&&
                                                            <>
                                                                {actionloading?
                                                                    <CircularLoader>
                                                                    </CircularLoader>:
                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                }    
                                            </>:
                                            <>
                                                {stage.status!=undefined&&stage.status!=false?
                                                    <div className="continue-input">
                                                        <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                    </div>:
                                                    <div className="continue-input">
                                                        <Button onClick={()=>{setDisplayoutput(true)}} style={{color: "#3A07CD"}}>Continue</Button>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    {getIsLast()==false?
                                        <>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div className="stage-params">
                                                    {/* <div className="stage-params-header">
                                                        Output
                                                    </div>
                                                    <div>
                                                        <pre>
                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                            }
                                                        </pre>
                                                        <pre>
                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                <DisplayObject
                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                    spacer={""}
                                                                    location={""}
                                                                    stageindex={stage.stageindex}
                                                                >
                                                                </DisplayObject>
                                                            }
                                                        </pre>
                                                    
                                                    </div> */}
                                                    <BlockOutput
                                                        stageindex={stage.stageindex}
                                                        pipelineid={pipelineid}
                                                    >
                                                    </BlockOutput>
                                                    
                                                    {stageComplete?
                                                        <>
                                                        </>:
                                                        <div className="stage-continue">
                                                            <Button 
                                                                style={{color: "#3A07CD"}}
                                                                onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                Continue
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            }    
                                        </>:
                                        <>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div style={{padding:10}}>
                                                    OK
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                    {stage.apptype=="system"&&
                        <>
                            {stage.app!==""&&
                                <div className="stage-action-container">
                                    <div>
                                        Action
                                    </div>
                                    {availableActions.length>0&&
                                        <div>
                                            <FormControl>
                                                <Select 
                                                        className="toiler-select-input"
                                                        value={stage.action} 
                                                        onChange={(e)=>{selectAppAction(e.target.value)}}
                                                >
                                                    {availableActions.map((action)=>{
                                                            return (
                                                                        <MenuItem value={action.id}>{action.name}</MenuItem>
                                                                    )   
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                            }
                            {stage.action!="choose_action"&&
                                <>
                                    <div className="stage-params">
                                        <div className="stage-params-header">
                                            Input Parameters
                                        </div>
                                        <div className="declare-variable">
                                            <DeclareVariable
                                                schema={stage.inputschema}
                                                schemamapping={stage.inputschemamapping}
                                                setschemaMapping={setInputSchemaMapping}
                                                modifySchema = {modifyInputSchema}
                                                setSchema={setInputSchemaStage}
                                                declaration={"sequential"}
                                                pipelinetype={pipelinetype}
                                                stageindex={stage.stageindex}
                                                stagetype={stage.type}
                                                layout="vertical"
                                                editable={true}
                                                pipelinestage = {pipelinestage}
                                                source={source}
                                                templateid={templateid}
                                                sourceid={pipelineid}
                                            >
                                            </DeclareVariable>
                                        </div>
                                        {getIsLast()==false?
                                            <>
                                                {stage.status!=undefined&&stage.status!=false?
                                                    <div className="continue-input">
                                                        {isLog==undefined&&
                                                            <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                        }
                                                        
                                                    </div>:
                                                    <div className="continue-input">
                                                        {/* {isLog==undefined&&
                                                            <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                        } */}
                                                        {isLog==undefined&&
                                                            <>
                                                                {actionloading?
                                                                    <CircularLoader>
                                                                    </CircularLoader>:
                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                }
                                                            </>
                                                        
                                                        }
                                                    </div>
                                                }    
                                            </>:
                                            <>
                                                {stage.status!=undefined&&stage.status!=false?
                                                    <div className="continue-input">
                                                        <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                    </div>:
                                                    <div className="continue-input">
                                                        <Button onClick={()=>{setDisplayoutput(true)}} style={{color: "#3A07CD"}}>Continue</Button>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    {getIsLast()==false?
                                        <>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div className="stage-params">
                                                    {/* <div className="stage-params-header">
                                                        Output
                                                    </div>
                                                    <div>
                                                        <pre>
                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                            }
                                                        </pre>
                                                        <pre>
                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                <DisplayObject
                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                    spacer={""}
                                                                    location={""}
                                                                    stageindex={stage.stageindex}
                                                                >
                                                                </DisplayObject>
                                                            }
                                                        </pre>
                                                    </div> */}
                                                    <BlockOutput
                                                        stageindex={stage.stageindex}
                                                        pipelineid={pipelineid}
                                                    >

                                                    </BlockOutput>
                                                    {stageComplete?
                                                        <>
                                                        </>:
                                                        <div className="stage-continue">
                                                            <Button 
                                                                style={{color: "#3A07CD"}}
                                                                onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                Continue
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            }    
                                        </>:
                                        <>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div style={{padding:10}}>
                                                    OK
                                                </div>
                                            }
                                        </>
                                    }                 
                                </>
                            }   
                        </>
                    }
                    {stage.apptype=="automata"&&
                        <>
                            {stage.app!==""&&
                                    <div className="stage-action-container">
                                        <div>
                                            Schema
                                        </div>
                                        {displaySchemaList.length>0&&
                                            <div>
                                                <FormControl>
                                                    <Select 
                                                            className="toiler-select-input"
                                                            value={stage.schema} 
                                                            onChange={(e)=>{selectSchemaAction(e.target.value)}}
                                                            renderValue={(v)=>{return v.schemaname}}
                                                    >
                                                        {displaySchemaList.map((action)=>{
                                                            return (
                                                                <MenuItem value={action}>{action.schemaname}</MenuItem>
                                                            )   
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                            }
                            {stage.schema!==""&&stage.schema!=undefined&&
                                <div className="stage-action-container">
                                    <div>
                                        Action
                                    </div>
                                    {availableActions.length>0?
                                        <div>
                                            <FormControl>
                                                <Select 
                                                    className="toiler-select-input"
                                                    value={stage.action} 
                                                    onChange={(e)=>{selectAppAction(e.target.value)}}

                                                >
                                                    {availableActions.map((action)=>{
                                                        return (
                                                            <MenuItem value={action?.id}>{action?.name}</MenuItem>
                                                        )   
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>:
                                        <div>
                                            {stage.action}
                                        </div>
                                    }
                                </div>
                            }
                            {(stage.action=="CREATE"||stage.action=="QUERY"||stage.action=="UPDATE"||stage.action=="DELETE"||stage.action=="REGISTER_EVENT"||stage.action=="SCHEDULE")?
                                <>
                                    {stage.action=="CREATE"&&
                                        <>
                                            <div className="stage-params">
                                                <div className="stage-params-header">
                                                    Input Parameters
                                                </div>
                                                <div className="declare-variable">
                                                    <DeclareVariable
                                                        schema={stage.inputschema}
                                                        schemamapping={stage.inputschemamapping}
                                                        setschemaMapping={setInputSchemaMapping}
                                                        modifySchema = {modifyInputSchema}
                                                        setSchema={setInputSchemaStage}
                                                        pipelinetype={pipelinetype}
                                                        stageindex={stage.stageindex}
                                                        stagetype={stage.type}
                                                        layout="vertical"
                                                        editable={true}
                                                        pipelinestage = {pipelinestage}
                                                        source={source}
                                                        templateid={templateid}
                                                        sourceid={pipelineid}
                                                    >
                                                    </DeclareVariable>
                                                </div>
                                                {stage.status!=undefined&&stage.status!=false?
                                                    <div className="continue-input">
                                                        {isLog==undefined&&
                                                            <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                        }
                                                    </div>:
                                                    <div className="continue-input">
                                                        {isLog==undefined&&
                                                            <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div className="stage-params">
                                                    {/* <div className="stage-params-header">
                                                        Output
                                                    </div>
                                                    <div>
                                                        <pre>
                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                            }
                                                        </pre>
                                                        <pre>
                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                <DisplayObject
                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                    spacer={""}
                                                                    location={""}
                                                                    stageindex={stage.stageindex}
                                                                >
                                                                </DisplayObject>
                                                            }
                                                        </pre>
                                                    </div> */}
                                                    <BlockOutput
                                                        stageindex={stage.stageindex}
                                                        pipelineid={pipelineid}
                                                    ></BlockOutput>
                                                    {stageComplete?
                                                        <>
                                                        </>:
                                                        <div className="stage-continue">
                                                            {isLog==undefined&&
                                                                <Button style={{color: "#3A07CD"}}
                                                                    onClick={()=>{
                                                                        setStageComplete(true);
                                                                        createnextstage();
                                                                    }}
                                                                >
                                                                    Continue
                                                                </Button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                    {stage.action=="QUERY"&&
                                        <>
                                            <div>
                                                <QueryBuilder
                                                    initapp = {stage.app}
                                                    initschema = {selectedSchema}
                                                    schema = {stage.inputschema}
                                                    schemamapping = {stage.inputschemamapping}
                                                    setschemaMapping = {setInputSchemaMapping}
                                                    modifySchema = {modifyInputSchema}
                                                    setSchema = {setInputSchemaStage}
                                                    pipelinetype = {pipelinetype}
                                                    stageindex = {stage.stageindex}
                                                    stagetype = {stage.type}
                                                    pipelinestage={pipelinestage}
                                                    templateid={templateid}
                                                    sourceid={pipelineid}
                                                ></QueryBuilder>
                                                {stage.status!=undefined&&stage.status!=false?
                                                        <div className="continue-input">
                                                            {isLog==undefined&&
                                                                <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                            }
                                                            
                                                        </div>:
                                                        <div className="continue-input">
                                                            {isLog==undefined&&
                                                                <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div className="stage-params">
                                                    {/* <div className="stage-params-header">
                                                        Output
                                                    </div>
                                                    <div>
                                                        <pre>
                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                            }
                                                        </pre>
                                                        <pre>
                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                <DisplayObject
                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                    spacer={""}
                                                                    location={""}
                                                                    stageindex={stage.stageindex}
                                                                >
                                                                </DisplayObject>
                                                            }
                                                        </pre>
                                                    </div> */}
                                                    <BlockOutput
                                                        stageindex={stage.stageindex}
                                                        pipelineid={pipelineid}
                                                    >

                                                    </BlockOutput>
                                                    {stageComplete?
                                                        <>
                                                        </>:
                                                        <div className="stage-continue">
                                                            {isLog==undefined&&
                                                                <Button style={{color: "#3A07CD"}} 
                                                                onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                    Continue
                                                                </Button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                    {stage.action=="UPDATE"&&
                                        <>  
                                            <div>
                                                <UpdateBuilder
                                                    initapp = {stage.app}
                                                    initschema = {selectedSchema}
                                                    schema = {stage.inputschema}
                                                    schemamapping = {stage.inputschemamapping}
                                                    setschemaMapping = {setInputSchemaMapping}
                                                    modifySchema = {modifyInputSchema}
                                                    setSchema = {setInputSchemaStage}
                                                    pipelinetype = {pipelinetype}
                                                    stageindex = {stage.stageindex}
                                                    stagetype = {stage.type}
                                                    pipelinestage={pipelinestage}
                                                    templateid={templateid}
                                                    sourceid={pipelineid}
                                                >
                                                </UpdateBuilder>
                                                {stage.status!=undefined&&stage.status!=false?
                                                        <div className="continue-input">
                                                            {isLog==undefined&&
                                                                <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                            }
                                                        </div>:
                                                        <div className="continue-input">
                                                            {isLog==undefined&&
                                                                <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div className="stage-params">
                                                    {/* <div className="stage-params-header">
                                                        Output
                                                    </div>
                                                    <div>
                                                        <pre>
                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                            }
                                                        </pre>
                                                        <pre>
                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                <DisplayObject
                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                    spacer={""}
                                                                    location={""}
                                                                    stageindex={stage.stageindex}
                                                                >
                                                                </DisplayObject>
                                                            }
                                                        </pre>
                                                    </div> */}
                                                    <BlockOutput
                                                        stageindex={stage.stageindex}
                                                        pipelineid={pipelineid}
                                                    >

                                                    </BlockOutput>
                                                    {stageComplete?
                                                        <>
                                                        </>:
                                                        <div className="stage-continue">
                                                            {isLog==undefined&&
                                                                <Button style={{color: "#3A07CD"}} 
                                                                onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                    Continue
                                                                </Button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                    {stage.action=="DELETE"&&
                                        <>
                                            <div>
                                                <DeleteBuilder
                                                    initapp = {stage.app}
                                                    initschema = {selectedSchema}
                                                    schema = {stage.inputschema}
                                                    schemamapping = {stage.inputschemamapping}
                                                    setschemaMapping = {setInputSchemaMapping}
                                                    modifySchema = {modifyInputSchema}
                                                    setSchema = {setInputSchemaStage}
                                                    pipelinetype = {pipelinetype}
                                                    stageindex = {stage.stageindex}
                                                    stagetype = {stage.type}
                                                    pipelinestage={pipelinestage}
                                                    templateid={templateid}
                                                    sourceid={pipelineid}
                                                >
                                                </DeleteBuilder>
                                                {stage.status!=undefined&&stage.status!=false?
                                                            <div className="continue-input">
                                                                {isLog==undefined&&
                                                                    <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                                }
                                                            </div>:
                                                            <div className="continue-input">
                                                                {isLog==undefined&&
                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                }
                                                            </div>
                                                    }
                                            </div>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div className="stage-params">
                                                    {/* <div className="stage-params-header">
                                                        Output
                                                    </div>
                                                    <div>
                                                        <pre>
                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                            }
                                                        </pre>
                                                        <pre>
                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                <DisplayObject
                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                    spacer={""}
                                                                    location={""}
                                                                    stageindex={stage.stageindex}
                                                                >
                                                                </DisplayObject>
                                                            }
                                                        </pre>
                                                    </div> */}
                                                    <BlockOutput
                                                        stageindex={stage.stageindex}
                                                        pipelineid={pipelineid}
                                                    >

                                                    </BlockOutput>
                                                    {stageComplete?
                                                        <>
                                                        </>:
                                                        <div className="stage-continue">
                                                            {isLog==undefined&&
                                                                <Button style={{color: "#3A07CD"}} 
                                                                onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                    Continue
                                                                </Button>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                    {stage.action=="REGISTER_EVENT"&&
                                        <>
                                            <div className="stage-action-container">
                                                <div>
                                                    Event
                                                </div>
                                                {displayEventList.length>0&&
                                                    <div>
                                                        <FormControl>
                                                            <Select 
                                                                className="toiler-select-input"
                                                                value={stage.event} 
                                                                onChange={(e)=>{selectAutomataEvent(e.target.value)}}
                                                            >
                                                                {displayEventList.map((action)=>{
                                                                    return (
                                                                        <MenuItem value={action.id}>{action.name}</MenuItem>
                                                                    )   
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                }
                                            </div>
                                            {(stage.event!=""&&stage.event!=undefined)&&
                                                <>
                                                    <div className="stage-params">
                                                        <div className="stage-params-header">
                                                            Input Parameters
                                                        </div>
                                                        <div className="declare-variable">
                                                            <DeclareVariable
                                                                schema={stage.inputschema}
                                                                schemamapping={stage.inputschemamapping}
                                                                setschemaMapping={setInputSchemaMapping}
                                                                modifySchema = {modifyInputSchema}
                                                                setSchema={setInputSchemaStage}
                                                                pipelinetype={pipelinetype}
                                                                stageindex={stage.stageindex}
                                                                stagetype={stage.type}
                                                                layout="vertical"
                                                                editable={true}
                                                                pipelinestage = {pipelinestage}
                                                                source={source}
                                                                templateid={templateid}
                                                                sourceid={pipelineid}
                                                            >
                                                            </DeclareVariable>
                                                        </div>
                                                        {stage.status!=undefined&&stage.status!=false?
                                                            <div className="continue-input">
                                                                {isLog==undefined&&
                                                                    <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                                }
                                                                
                                                            </div>:
                                                            <div className="continue-input">
                                                                {isLog==undefined&&
                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                }
                                                                
                                                            </div>
                                                        }
                                                    </div>
                                                    {stage.status!=undefined&&stage.status!=false&&
                                                        <div className="stage-params">
                                                            {/* <div className="stage-params-header">
                                                                Output
                                                            </div>
                                                            <div>
                                                                <pre>
                                                                    {getStageError(stage.stageindex)!==undefined&&
                                                                        JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                                    }
                                                                </pre>
                                                                <pre>
                                                                    {getOutputSchema(stage.stageindex).length>0&&
                                                                        <DisplayObject
                                                                            schema={getOutputSchema(stage.stageindex)}
                                                                            spacer={""}
                                                                            location={""}
                                                                            stageindex={stage.stageindex}
                                                                        >
                                                                        </DisplayObject>
                                                                    }
                                                                </pre>
                                                            </div> */}
                                                            <BlockOutput
                                                                stageindex={stage.stageindex}
                                                                pipelineid={pipelineid}
                                                            >

                                                            </BlockOutput>
                                                            {stageComplete?
                                                                <>
                                                                </>:
                                                                <div className="stage-continue">
                                                                    {isLog==undefined&&
                                                                        <Button style={{color: "#3A07CD"}}
                                                                        onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                            Continue
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }      
                                        </>
                                    }
                                    {stage.action=="SCHEDULE"&&
                                        <>
                                            <div className="stage-action-container">
                                                <div>
                                                    Function
                                                </div>
                                                {schTriggers.length>0?
                                                            <div>
                                                                <FormControl>
                                                                    <Select 
                                                                        className="toiler-select-input"
                                                                        value={stage.pipeline} 
                                                                        onChange={(e)=>{selectSchTrigger(e.target.value)}}

                                                                    >
                                                                        {schTriggers.map((action)=>{
                                                                            return (
                                                                                <MenuItem value={action.id}>{action.name}</MenuItem>
                                                                            )   
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>:
                                                            <div>
                                                                
                                                            </div>
                                                }
                                            </div>
                                            {stage.pipeline!=""&&stage.pipeline!=undefined&&
                                                <>
                                                    <div className="stage-params">
                                                        <div className="stage-params-header">
                                                            Input Parameters
                                                        </div>
                                                        <div className="declare-variable">
                                                            <DeclareVariable
                                                                schema={stage.inputschema}
                                                                schemamapping={stage.inputschemamapping}
                                                                setschemaMapping={setInputSchemaMapping}
                                                                modifySchema = {modifyInputSchema}
                                                                setSchema={setInputSchemaStage}
                                                                pipelinetype={pipelinetype}
                                                                stageindex={stage.stageindex}
                                                                stagetype={stage.type}
                                                                layout="vertical"
                                                                editable={true}
                                                                pipelinestage = {pipelinestage}
                                                                source={source}
                                                                templateid={templateid}
                                                                sourceid={pipelineid}
                                                            >
                                                            </DeclareVariable>
                                                        </div>
                                                        {stage.status!=undefined&&stage.status!=false?
                                                            <div className="continue-input">
                                                                {isLog==undefined&&
                                                                    <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                                }
                                                                
                                                            </div>:
                                                            <div className="continue-input">
                                                                {isLog==undefined&&
                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                }
                                                                
                                                            </div>
                                                        }
                                                    </div>
                                                    {stage.status!=undefined&&stage.status!=false&&
                                                        <div className="stage-params">
                                                            {/* <div className="stage-params-header">
                                                                Output
                                                            </div>
                                                            <div>
                                                                <pre>
                                                                    {getStageError(stage.stageindex)!==undefined&&
                                                                        JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                                    }
                                                                </pre>
                                                                <pre>
                                                                    {getOutputSchema(stage.stageindex).length>0&&
                                                                        <DisplayObject
                                                                            schema={getOutputSchema(stage.stageindex)}
                                                                            spacer={""}
                                                                            location={""}
                                                                            stageindex={stage.stageindex}
                                                                        >
                                                                        </DisplayObject>
                                                                    }
                                                                </pre>
                                                            </div> */}
                                                            <BlockOutput
                                                                stageindex={stage.stageindex}
                                                                pipelineid={pipelineid}
                                                            >
                                                            </BlockOutput>
                                                            {stageComplete?
                                                                <>
                                                                </>:
                                                                <div className="stage-continue">
                                                                    {isLog==undefined&&
                                                                        <Button style={{color: "#3A07CD"}} 
                                                                        onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                            Continue
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>:
                                <>
                            `        <div className="stage-params">
                                        <div className="stage-params-header">
                                            Input Parameters
                                        </div>
                                        <div className="declare-variable">
                                            <DeclareVariable
                                                schema={stage.inputschema}
                                                schemamapping={stage.inputschemamapping}
                                                setschemaMapping={setInputSchemaMapping}
                                                modifySchema = {modifyInputSchema}
                                                setSchema={setInputSchemaStage}
                                                pipelinetype={pipelinetype}
                                                stageindex={stage.stageindex}
                                                stagetype={stage.type}
                                                layout="vertical"
                                                editable={true}
                                                pipelinestage = {pipelinestage}
                                                source={source}
                                                templateid={templateid}
                                                sourceid={pipelineid}
                                            >
                                            </DeclareVariable>
                                        </div>
                                        {stage.status!=undefined&&stage.status!=false?
                                            <div className="continue-input">
                                                {isLog==undefined&&
                                                    <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                }
                                                
                                            </div>:
                                            <div className="continue-input">
                                                {isLog==undefined&&
                                                    <>
                                                        {actionloading?
                                                            <CircularLoader>
                                                            </CircularLoader>:
                                                            <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {stage.status!=undefined&&stage.status!=false&&
                                        <div className="stage-params">
                                            {/* <div className="stage-params-header">
                                                Output
                                            </div>
                                            <div>
                                                <pre>
                                                    {getStageError(stage.stageindex)!==undefined&&
                                                        JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                    }
                                                </pre>
                                                <pre>
                                                    {getOutputSchema(stage.stageindex).length>0&&
                                                        <DisplayObject
                                                            schema={getOutputSchema(stage.stageindex)}
                                                            spacer={""}
                                                            location={""}
                                                            stageindex={stage.stageindex}
                                                        >
                                                        </DisplayObject>
                                                    }
                                                </pre>
                                            </div> */}
                                            <BlockOutput
                                                stageindex={stage.stageindex}
                                                pipelineid={pipelineid}
                                            >
                                            </BlockOutput>
                                            {stageComplete?
                                                <>
                                                </>:
                                                <div className="stage-continue">
                                                    {isLog==undefined&&
                                                        <Button style={{color: "#3A07CD"}} 
                                                        onClick={()=>{
                                                            setStageComplete(true);
                                                            createnextstage();
                                                        }}>
                                                            Continue
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </>
                            }                   
                        </>
                    }
                    {stage.apptype=="transform"&&
                        <>
                            {stage.app!==""&&
                                <div className="stage-action-container">
                                    <div>
                                        Type
                                    </div>
                                    {availableTypes.length>0?
                                        <div>
                                            <FormControl>
                                                <Select 
                                                    className="toiler-select-input"
                                                    value={stage.transformtype} 
                                                    onChange={(e)=>{selectTypeAction(e.target.value)}}
                                                >
                                                    {availableTypes.map((type)=>{
                                                        return (
                                                            <MenuItem value={type.value}>{type.name}</MenuItem>
                                                        )   
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>:
                                        <div>
                                            {stage.type}
                                        </div>
                                    }
                                </div>
                            }
                            {stage.transformtype!==""&&
                                <div className="stage-action-container">
                                    <div>
                                        Action
                                    </div>
                                    {availableActions.length>0?
                                        <div>
                                            <FormControl>
                                                <Select 
                                                    className="toiler-select-input"
                                                    value={stage.action} 
                                                    onChange={(e)=>{selectAppAction(e.target.value)}}
                                                >
                                                    {availableActions.map((action)=>{
                                                        return (
                                                            <MenuItem value={action.id}>{action.name}</MenuItem>
                                                        )   
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>:
                                        <div>
                                            {stage.action}
                                        </div>
                                    }
                                </div>
                            }
                            {stage.action!="choose_action"&&
                                <>
                                    <div className="stage-params">
                                        <div className="stage-params-header">
                                            Input Parameters
                                        </div>
                                        <div className="declare-variable">
                                            <DeclareVariable
                                                schema={stage.inputschema}
                                                schemamapping={stage.inputschemamapping}
                                                setschemaMapping={setInputSchemaMapping}
                                                modifySchema = {modifyInputSchema}
                                                setSchema={setInputSchemaStage}
                                                pipelinetype={pipelinetype}
                                                stageindex={stage.stageindex}
                                                stagetype={stage.type}
                                                layout="vertical"
                                                editable={true}
                                                pipelinestage = {pipelinestage}
                                                source={source}
                                                templateid={templateid}
                                                sourceid={pipelineid}
                                            >
                                            </DeclareVariable>
                                        </div>
                                        {stage.status!=undefined&&stage.status!=false?
                                            <div className="continue-input">
                                                {isLog==undefined&&
                                                    <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                }
                                                
                                            </div>:
                                            <div className="continue-input">
                                                {isLog==undefined&&
                                                    <>
                                                        {actionloading?
                                                            <CircularLoader>
                                                            </CircularLoader>:
                                                            <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {stage.status!=undefined&&stage.status!=false&&
                                        <div className="stage-params">
                                            {/* <div className="stage-params-header">
                                                Output
                                            </div>
                                            <div>
                                                <pre>
                                                    {getStageError(stage.stageindex)!==undefined&&
                                                        JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                    }
                                                </pre>
                                                <pre>
                                                    <DisplayObject
                                                        schema={getOutputSchema(stage.stageindex)}
                                                        spacer={""}
                                                        location={""}
                                                        stageindex={stage.stageindex}
                                                    >
                                                    </DisplayObject>
                                                </pre>
                                            </div> */}
                                            <BlockOutput
                                                stageindex={stage.stageindex}
                                                pipelineid={pipelineid}
                                            >
                                            </BlockOutput>
                                            {stageComplete?
                                                <>
                                                </>:
                                                <div className="stage-continue">
                                                    {isLog==undefined&&
                                                        <Button style={{color: "#3A07CD"}}
                                                        onClick={
                                                            ()=>{
                                                                    setStageComplete(true);
                                                                    createnextstage();
                                                                    }}
                                                        >
                                                            Continue
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                    {stage.apptype=="template"&&
                        <>
                            {stage.app!=""&&
                                <div className="stage-action-container">
                                    <div>
                                        Action
                                    </div>
                                    {availableActions.length>0&&
                                        <div>
                                            <FormControl>
                                                <Select 
                                                        className="toiler-select-input"
                                                        value={stage.action} 
                                                        onChange={(e)=>{selectAppAction(e.target.value)}}
                                                >
                                                    {availableActions.map((action)=>{
                                                            return (
                                                                        <MenuItem value={action.id}>{action.name}</MenuItem>
                                                                    )   
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                            }
                            {stage.action!="choose_action"&&
                                <>
                                    <div className="stage-params">
                                        <div className="stage-params-header">
                                            Input Parameters
                                        </div>
                                        <div className="declare-variable">
                                            <DeclareVariable
                                                schema={stage.inputschema}
                                                schemamapping={stage.inputschemamapping}
                                                setschemaMapping={setInputSchemaMapping}
                                                modifySchema = {modifyInputSchema}
                                                setSchema={setInputSchemaStage}
                                                declaration={"sequential"}
                                                pipelinetype={pipelinetype}
                                                stageindex={stage.stageindex}
                                                stagetype={stage.type}
                                                layout="vertical"
                                                editable={true}
                                                pipelinestage = {pipelinestage}
                                                source={source}
                                                templateid={templateid}
                                                sourceid={pipelineid}
                                            >
                                            </DeclareVariable>
                                        </div>
                                        {getIsLast()==false?
                                            <>
                                                {stage.status!=undefined&&stage.status!=false?
                                                    <div className="continue-input">
                                                        {isLog==undefined&&
                                                            <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                        }
                                                        
                                                    </div>:
                                                    <div className="continue-input">
                                                        {/* {isLog==undefined&&
                                                            <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                        } */}
                                                        {isLog==undefined&&
                                                            <>
                                                                {actionloading?
                                                                    <CircularLoader>
                                                                    </CircularLoader>:
                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                }
                                                            </>
                                                        
                                                        }
                                                    </div>
                                                }    
                                            </>:
                                            <>
                                                {stage.status!=undefined&&stage.status!=false?
                                                    <div className="continue-input">
                                                        <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                    </div>:
                                                    <div className="continue-input">
                                                        <Button onClick={()=>{setDisplayoutput(true)}} style={{color: "#3A07CD"}}>Continue</Button>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    {getIsLast()==false?
                                        <>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div className="stage-params">
                                                    {/* <div className="stage-params-header">
                                                        Output
                                                    </div>
                                                    <div>
                                                        <pre>
                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                            }
                                                        </pre>
                                                        <pre>
                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                <DisplayObject
                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                    spacer={""}
                                                                    location={""}
                                                                    stageindex={stage.stageindex}
                                                                >
                                                                </DisplayObject>
                                                            }
                                                        </pre>
                                                    </div> */}
                                                    <BlockOutput
                                                        stageindex={stage.stageindex}
                                                        pipelineid={pipelineid}
                                                    >
                                                    </BlockOutput>
                                                    {stageComplete?
                                                        <>
                                                        </>:
                                                        <div className="stage-continue">
                                                            <Button 
                                                                style={{color: "#3A07CD"}}
                                                                onClick={()=>{
                                                                            setStageComplete(true);
                                                                            createnextstage();
                                                                        }}>
                                                                Continue
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            }    
                                        </>:
                                        <>
                                            {stage.status!=undefined&&stage.status!=false&&
                                                <div style={{padding:10}}>
                                                    OK
                                                </div>
                                            }
                                        </>
                                    }                 
                                </>
                            }
                        </>
                    }
                    {stage.apptype=="window"&&
                        <>
                            {stage.app!=""&&
                                <div className="stage-action-container">
                                    <div>
                                        Action
                                    </div>
                                    {availableActions.length>0&&
                                        <div>
                                            <FormControl>
                                                <Select
                                                    className="toiler-select-input"
                                                    value={stage.action}
                                                    onChange={
                                                                (e)=>{
                                                                    selectAppAction(e.target.value)
                                                                }
                                                             }
                                                >
                                                    {availableActions.map((action)=>{
                                                        return (
                                                            <MenuItem value={action.value}>{action.name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                            }
                            {stage.action!="choose_action"&&
                                <>
                                    {stage.action=="alert"?
                                        <div>
                                            <div className="stage-params">
                                                <div className="stage-params-header">
                                                    Input Parameters
                                                </div>
                                                <div className="declare-variable">
                                                    <DeclareVariable
                                                        schema={stage.inputschema}
                                                        schemamapping={stage.inputschemamapping}
                                                        setschemaMapping={setInputSchemaMapping}
                                                        modifySchema = {modifyInputSchema}
                                                        setSchema={setInputSchemaStage}
                                                        declaration={"sequential"}
                                                        pipelinetype={pipelinetype}
                                                        stageindex={stage.stageindex}
                                                        stagetype={stage.type}
                                                        layout="vertical"
                                                        editable={true}
                                                        pipelinestage = {pipelinestage}
                                                        source={source}
                                                        templateid={templateid}
                                                        sourceid={pipelineid}
                                                    >
                                                    </DeclareVariable>
                                                </div>
                                                {getIsLast()==false?
                                                    <>
                                                        {stage.status!=undefined&&stage.status!=false?
                                                            <div className="continue-input">
                                                                {isLog==undefined&&
                                                                    <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                                }
                                                                
                                                            </div>:
                                                            <div className="continue-input">
                                                                {/* {isLog==undefined&&
                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                } */}
                                                                {isLog==undefined&&
                                                                    <>
                                                                        {actionloading?
                                                                            <CircularLoader>
                                                                            </CircularLoader>:
                                                                            <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                        }
                                                                    </>
                                                                
                                                                }
                                                            </div>
                                                        }    
                                                    </>:
                                                    <>
                                                        {stage.status!=undefined&&stage.status!=false?
                                                            <div className="continue-input">
                                                                <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                            </div>:
                                                            <div className="continue-input">
                                                                <Button onClick={()=>{setDisplayoutput(true)}} style={{color: "#3A07CD"}}>Continue</Button>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            {getIsLast()==false?
                                                <>
                                                    {stage.status!=undefined&&stage.status!=false&&
                                                        <div className="stage-params">
                                                            {/* <div className="stage-params-header">
                                                                Output
                                                            </div>
                                                            <div>
                                                                <pre>
                                                                    {getStageError(stage.stageindex)!==undefined&&
                                                                        JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                                    }
                                                                </pre>
                                                                <pre>
                                                                    {getOutputSchema(stage.stageindex).length>0&&
                                                                        <DisplayObject
                                                                            schema={getOutputSchema(stage.stageindex)}
                                                                            spacer={""}
                                                                            location={""}
                                                                            stageindex={stage.stageindex}
                                                                        >
                                                                        </DisplayObject>
                                                                    }
                                                                </pre>
                                                            </div> */}
                                                            <BlockOutput
                                                                stageindex={stage.stageindex}
                                                                pipelineid={pipelineid}
                                                            >
                                                            </BlockOutput>
                                                            {stageComplete?
                                                                <>
                                                                </>:
                                                                <div className="stage-continue">
                                                                    <Button 
                                                                        style={{color: "#3A07CD"}}
                                                                        onClick={()=>{
                                                                                    setStageComplete(true);
                                                                                    createnextstage();
                                                                                }}>
                                                                        Continue
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }    
                                                </>:
                                                <>
                                                    {stage.status!=undefined&&stage.status!=false&&
                                                        <div style={{padding:10}}>
                                                            OK
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>:
                                        <div>
                                            <div className="stage-action-container">
                                                <div>
                                                    Function
                                                </div>
                                                {templatepipelinesactions.length>0&&
                                                    <div>
                                                        <FormControl>
                                                            <Select
                                                                className="toiler-select-input"
                                                                value={stage.action}
                                                                onChange={
                                                                            (e)=>{
                                                                                selectFunctionAction(e.target.value)
                                                                            }
                                                                        }
                                                            >
                                                                {templatepipelinesactions.map((action)=>{
                                                                    return (
                                                                        <MenuItem value={action.id}>{action.name}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                }
                                            </div>
                                            {stage.pipelineid!=undefined&&
                                                <div>
                                                    <div className="stage-params">
                                                        <div className="stage-params-header">
                                                            Input Parameters
                                                        </div>
                                                        <div className="declare-variable">
                                                            <DeclareVariable
                                                                schema={stage.inputschema}
                                                                schemamapping={stage.inputschemamapping}
                                                                setschemaMapping={setInputSchemaMapping}
                                                                modifySchema = {modifyInputSchema}
                                                                setSchema={setInputSchemaStage}
                                                                declaration={"sequential"}
                                                                pipelinetype={pipelinetype}
                                                                stageindex={stage.stageindex}
                                                                stagetype={stage.type}
                                                                layout="vertical"
                                                                editable={true}
                                                                pipelinestage = {pipelinestage}
                                                                source={source}
                                                                templateid={templateid}
                                                                sourceid={pipelineid}
                                                            >
                                                            </DeclareVariable>
                                                        </div>
                                                        {getIsLast()==false?
                                                            <>
                                                                {stage.status!=undefined&&stage.status!=false?
                                                                    <div className="continue-input">
                                                                        {isLog==undefined&&
                                                                            <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                                        }
                                                                    </div>:
                                                                    <div className="continue-input">
                                                                        {isLog==undefined&&
                                                                            <>
                                                                                {actionloading?
                                                                                    <CircularLoader>
                                                                                    </CircularLoader>:
                                                                                    <Button onClick={()=>{testaction()}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                                }
                                                                            </>
                                                                        
                                                                        }
                                                                    </div>
                                                                }    
                                                            </>:
                                                            <>
                                                                {stage.status!=undefined&&stage.status!=false?
                                                                    <div className="continue-input">
                                                                        <Button onClick={()=>{setDisplayoutput(false)}} style={{color: "#3A07CD"}}>Edit</Button>
                                                                    </div>:
                                                                    <div className="continue-input">
                                                                        <Button onClick={()=>{setDisplayoutput(true)}} style={{color: "#3A07CD"}}>Continue</Button>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    {getIsLast()==false?
                                                        <>
                                                            {stage.status!=undefined&&stage.status!=false&&
                                                                <div className="stage-params">
                                                                    {/* <div className="stage-params-header">
                                                                        Output
                                                                    </div>
                                                                    <div>
                                                                        <pre>
                                                                            {getStageError(stage.stageindex)!==undefined&&
                                                                                JSON.stringify(getStageError(stage.stageindex), null, 2)
                                                                            }
                                                                        </pre>
                                                                        <pre>
                                                                            {getOutputSchema(stage.stageindex).length>0&&
                                                                                <DisplayObject
                                                                                    schema={getOutputSchema(stage.stageindex)}
                                                                                    spacer={""}
                                                                                    location={""}
                                                                                    stageindex={stage.stageindex}
                                                                                >
                                                                                </DisplayObject>
                                                                            }
                                                                        </pre>
                                                                    </div> */}
                                                                    <BlockOutput
                                                                        stageindex={stage.stageindex}
                                                                        pipelineid={pipelineid}
                                                                    >
                                                                    </BlockOutput>
                                                                    {stageComplete?
                                                                        <>
                                                                        </>:
                                                                        <div className="stage-continue">
                                                                            <Button 
                                                                                style={{color: "#3A07CD"}}
                                                                                onClick={()=>{
                                                                                            setStageComplete(true);
                                                                                            createnextstage();
                                                                                        }}>
                                                                                Continue
                                                                            </Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }    
                                                        </>:
                                                        <>
                                                            {stage.status!=undefined&&stage.status!=false&&
                                                                <div style={{padding:10}}>
                                                                    OK
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            }
        </>
    )
}

export default SingleBlock;